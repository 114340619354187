import * as React from 'react';
import { observer } from 'mobx-react-lite';
import Title from 'components/title';
import { RouteComponentProps } from '@reach/router';
import { StoreContext } from 'stores';
import ActivityIndicator from 'components/activityIndicator';
import { PhoneBidder } from '../../models/phoneBidder';
import PhoneBidderBox from './components/phoneBidderBox';
import { Typography } from '@mui/material';

export interface PhoneBiddersProps extends RouteComponentProps {
	auctionId: string;
}

const PhoneBidders: React.FunctionComponent<PhoneBiddersProps> = observer((props) => {
	const { phoneUsersStore } = React.useContext(StoreContext);
	const bidders = phoneUsersStore.get_bidders(props.auctionId);

	if (!bidders) {
		return <ActivityIndicator />;
	}

	const groupedByPerson = bidders.reduce((curr, next) => {
		const bidderList = curr.get(next.user.userId);
		if (bidderList) {
			bidderList.push(next);
		} else {
			curr.set(next.user.userId, [next]);
		}

		return curr;
	}, new Map<number, PhoneBidder[]>());

	return (
		<React.Fragment>
			<Title>Telefon Bydere</Title>
			{bidders.length === 0 && (
				<Typography variant="body1">Der er endnu ikke nogen tilmeldt denne auktion</Typography>
			)}
			{Array.from(groupedByPerson.keys())
				.map((x) => groupedByPerson.get(x)!)
				.map((x) => (
					<PhoneBidderBox key={`${x[0].user.userId}`} phoneBidders={x} />
				))}
		</React.Fragment>
	);
});

export default PhoneBidders;
