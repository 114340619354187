import * as React from 'react';
import { PhoneBidder } from '../../../models/phoneBidder';
import { Paper, Typography, Table, TableHead, TableRow, TableCell, TableBody, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import currencyFormatter from 'utilities/currency_formatter';
import TableHeaderLabel from 'components/headerLabel';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			paddingTop: theme.spacing(2),
			paddingLeft: theme.spacing(2)
		},
		phone: {
			paddingLeft: theme.spacing(2)
		},
		userItem: {
			marginBottom: theme.spacing(2)
		}
	})
);

export interface PhoneBidderBoxProps {
	phoneBidders: PhoneBidder[];
}

const PhoneBidderBox: React.FunctionComponent<PhoneBidderBoxProps> = ({ phoneBidders }) => {
	const styles = useStyles();
	const bids = phoneBidders;
	const user = bids[0].user;

	let title = '';
	if (user.companyName && user.firstName) {
		title = `${user.companyName} ved ${user.firstName} ${user.lastName}`;
	} else if (user.companyName) {
		title = `${user.companyName}`;
	} else {
		title = `${user.firstName} ${user.lastName}`;
	}

	const phoneLabel = (no: string) => <a href={`tel:${no}`}>{no}</a>;

	return (
		<Paper key={`${user.userId}`} className={styles.userItem}>
			<Typography className={styles.root} variant="h6">
				{title}
			</Typography>
			<Typography className={styles.phone} variant="subtitle1">
				Telefon: {phoneLabel(user.primaryPhone)}
			</Typography>
			{user.secondaryPhone && (
				<Typography className={styles.phone} variant="subtitle1">
					Sekundær telefon: {phoneLabel(user.secondaryPhone)}
				</Typography>
			)}
			{user.note && (
				<Typography className={styles.phone} variant="subtitle1">
					{user.note}
				</Typography>
			)}
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							<TableHeaderLabel>Katalog #</TableHeaderLabel>
						</TableCell>
						<TableCell>
							<TableHeaderLabel>Sikkerhedsbud</TableHeaderLabel>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{bids
						.sort((x, y) => x.lot.catalogNumber - y.lot.catalogNumber)
						.map((bid) => (
							<TableRow key={`${bid.user.userId}-${bid.lot.lotId}`}>
								<TableCell>{bid.lot.catalogNumber}</TableCell>
								<TableCell>{currencyFormatter(bid.bid)}</TableCell>
							</TableRow>
						))}
				</TableBody>
			</Table>
		</Paper>
	);
};

export default PhoneBidderBox;
