import * as React from 'react';
import { TableRow, TableCell, Button } from '@mui/material';
import CatalogImage from 'components/catalogImage';
import { LotModel } from 'models/lotModel';
import { truncate } from 'utilities/truncate';
import { navigate } from '@reach/router';
import PhotoIcon from '@mui/icons-material/Photo';

export interface SectionRowCellProps {
	catalogId: string;
	lot: LotModel;
}

const SectionRowCell: React.FunctionComponent<SectionRowCellProps> = ({ catalogId, lot }) => {
	const handleSelectLot = (lotId: string) => {
		navigate(`/catalogs/${catalogId}/lots/${lotId}`);
	};

	return (
		<TableRow style={{ cursor: 'pointer' }} onClick={() => handleSelectLot(lot.id)} key={lot.id}>
			<TableCell align="center" style={{ width: '20px' }}>
				{lot.catalogNumber}
			</TableCell>
			<TableCell align="center" style={{ width: '100px' }}>
				{lot.images.length > 0 && <CatalogImage image={lot.images[0].thumbnail} width={100} />}
				{lot.images.length === 0 && <PhotoIcon />}
			</TableCell>
			<TableCell>{truncate(lot.text, 70)}</TableCell>
			<TableCell align="right">
				<Button variant="outlined" onClick={() => handleSelectLot(lot.id)}>
					Detaljer
				</Button>
			</TableCell>
		</TableRow>
	);
};

export default SectionRowCell;
