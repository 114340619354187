const formatter = new Intl.NumberFormat('da-DK', {
	style: 'currency',
	currency: 'DKK',
	minimumFractionDigits: 0
});

const func = (value: number): string => {
	return formatter.format(value);
};

export default func;
