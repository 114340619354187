import { navigate, RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { StoreContext } from 'stores/index';

export interface RootViewProps extends RouteComponentProps {}

const RootView: React.FunctionComponent<RootViewProps> = () => {
	const { userStore } = React.useContext(StoreContext);
	React.useEffect(() => {
		if (userStore.isAuthenticated) {
			navigate('/auctions');
		} else {
			navigate('/logon');
		}

		return () => {};
	}, [userStore.isAuthenticated]);

	return <div>{'Redirecting'}</div>;
};

export default RootView;
