import * as React from 'react';
import { FormControlLabel, Switch } from '@mui/material';

interface SwitchInputProps {
	label: string;
	name: string;
	object: any;
	onChange: (event: any) => void;
	style?: React.CSSProperties;
}

const SwitchInput = (props: SwitchInputProps) => {
	return (
		<FormControlLabel
			style={props.style}
			control={
				<Switch
					checked={props.object[props.name]}
					onChange={props.onChange}
					name={props.name}
					value={props.object[props.name] ? props.object[props.name] : false}
				/>
			}
			label={props.label}
		/>
	);
};

export default SwitchInput;
