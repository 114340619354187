import * as React from 'react';
import { TableRow, TableCell, Button } from '@mui/material';
import CatalogImage from 'components/catalogImage';
import '../utilities/ArrayMinMax';
import NewsImage from './newsImage';
import RowImage from 'models/interfaces/rowImage';
import { observer } from 'mobx-react-lite';

export interface OrderedImagePresentationRowProps {
	images: RowImage[];
	image: RowImage;
	baseUrl: string;
	isCatalog: boolean;
	onDelete: (image: RowImage) => void;
	onReorder: (itemId: string, up: boolean) => void;
}

const OrderedImagePresentationRow: React.FunctionComponent<OrderedImagePresentationRowProps> = observer((props) => {
	const { image, images, onReorder } = props;

	const changeRows = (up: boolean) => {
		onReorder(props.image.id, up);
	};

	return (
		<TableRow>
			<TableCell>
				{props.isCatalog && <CatalogImage width={100} image={image.thumbnail} />}
				{!props.isCatalog && <NewsImage width={100} image={image.thumbnail} />}
			</TableCell>
			<TableCell align="left">
				<a download href={image.image.source} target="_blank" rel="noopener noreferrer">
					Fuld størrelse
				</a>
			</TableCell>
			<TableCell align="right">
				{image.order > 0 && (
					<Button
						variant="outlined"
						onClick={() => {
							changeRows(true);
						}}
					>
						Op
					</Button>
				)}
				{image.order < images.length - 1 && (
					<Button
						style={{ margin: '1em' }}
						variant="outlined"
						onClick={() => {
							changeRows(false);
						}}
					>
						Ned
					</Button>
				)}
			</TableCell>
			<TableCell align="right">
				<Button
					variant="outlined"
					onClick={() => {
						props.onDelete(props.image);
					}}
				>
					Slet
				</Button>
			</TableCell>
		</TableRow>
	);
});

export default OrderedImagePresentationRow;
