export default class PhoneUser {
	id: string;
	companyName: string;
	firstName: string;
	lastName: string;
	address: string;
	postalCode: string;
	primaryPhone: string;
	secondaryPhone: string;
	country: string;
	note: string;

	private searchString: string;

	constructor(user: any) {
		this.id = user.id;
		this.companyName = user.companyName ? user.companyName : '';
		this.firstName = user.firstName ? user.firstName : '';
		this.lastName = user.lastName ? user.lastName : '';
		this.address = user.address ? user.address : '';
		this.postalCode = user.postalCode ? user.postalCode : '';
		this.primaryPhone = user.primaryPhone ? user.primaryPhone : '';
		this.secondaryPhone = user.secondaryPhone ? user.secondaryPhone : '';
		this.country = user.country ? user.country : '';
		this.note = user.note ? user.note : '';

		this.searchString = `${this.companyName} ${this.firstName} ${this.lastName} ${this.address} ${this.postalCode} ${this.primaryPhone} ${this.secondaryPhone} ${this.country} ${this.note}`;
	}

	contains(searchText: string) {
		return this.searchString.toLowerCase().includes(searchText.toLowerCase());
	}
}
