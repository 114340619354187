import { Button, Divider, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RouteComponentProps } from '@reach/router';
import Network, { ServiceEndpoint } from 'common/network';
import { IHighestBidderForLotSectionDto } from 'common/responses/IHighestBidderForLotSectionDto';
import ActivityIndicator from 'components/activityIndicator';
import Title from 'components/title';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { StoreContext } from 'stores';
import LotBids from './components/lotBids';

const useStyles = makeStyles((theme) => ({
	divider: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3)
	}
}));

export interface OnlineBiddersProps extends RouteComponentProps {
	auctionId: string;
}

const OnlineBidders: React.FunctionComponent<OnlineBiddersProps> = observer((props) => {
	const styles = useStyles();
	const { auctionsStore } = React.useContext(StoreContext);
	const [getBidSections, setBidSections] = React.useState<IHighestBidderForLotSectionDto[] | undefined>(undefined);
	const [failedToLoad, setFailedToLoad] = React.useState(false);

	const loadData = () => {
		Network.get<IHighestBidderForLotSectionDto[]>(
			ServiceEndpoint.Node,
			`consignmentbids/${props.auctionId}/highest_bids`
		)
			.then(setBidSections)
			.catch((x) => setFailedToLoad(true));
	};

	React.useEffect(() => {
		loadData();
	}, [setBidSections, props.auctionId, setFailedToLoad]);

	if (failedToLoad) {
		return (
			<React.Fragment>
				<Title>Failed to load data</Title>
				<Button
					variant="outlined"
					onClick={() => {
						setFailedToLoad(false);
						loadData();
					}}
				>
					Retry
				</Button>
			</React.Fragment>
		);
	}

	const auction = auctionsStore.get_auction(props.auctionId);
	if (!auction) {
		return <ActivityIndicator message="Loading Auction" />;
	}

	if (!getBidSections) {
		return <ActivityIndicator message="Loading Bids" />;
	}

	return (
		<React.Fragment>
			<Title>
				Kommisions bud for {auction.title} afholdt {auction.date && auction.date.toLocaleString()}
			</Title>

			{getBidSections.length === 0 && (
				<Typography variant="body1">Der er endnu ikke nogen bud på denne auktion</Typography>
			)}

			{getBidSections.map((section) => (
				<React.Fragment key={`SectionFromLot${section.fromLot}`}>
					<Typography gutterBottom variant="h6">
						{section.title}
					</Typography>

					<Grid container>
						{section.lots.map((bid) => (
							<Grid item key={`LotBids${bid.lot.catalogNumber}`} xs={12} sm={6} md={4} lg={2} xl={2}>
								<LotBids auctionId={props.auctionId} bid={bid} />
							</Grid>
						))}
					</Grid>
					<Divider className={styles.divider} />
				</React.Fragment>
			))}
		</React.Fragment>
	);
});

export default OnlineBidders;
