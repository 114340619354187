import * as React from 'react';
import Title from 'components/title';
import { RouteComponentProps, navigate } from '@reach/router';
import { useForm } from 'common/form/useForm';
import { StoreContext } from 'stores/index';
import { FormGroup, FormControl, InputLabel, Input, Theme, Divider, Button, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import ActivityIndicator from 'components/activityIndicator';
import { observer } from 'mobx-react-lite';
import { CatalogSectionModel } from '../../models/catalogSectionModel';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		element: {
			margin: theme.spacing(2, 0)
		},
		divider: {
			margin: theme.spacing(2, 2, 2, 2)
		}
	})
);

export interface EditCatalogSectionProps extends RouteComponentProps {
	catalogId: string;
	sectionId: string;
}

const EditCatalogSection: React.FunctionComponent<EditCatalogSectionProps> = observer(({ catalogId, sectionId }) => {
	const styles = useStyles();

	const { catalogStore } = React.useContext(StoreContext);

	let section: CatalogSectionModel | undefined = undefined;
	if (sectionId !== 'create') {
		section = catalogStore.section(catalogId, sectionId);
	}

	const defaultSection = new CatalogSectionModel({
		title: 'Ny Sektion',
		fromLot: 0
	});

	const initial: any = sectionId === 'create' ? defaultSection : {};

	const { handleSubmit, handleInputChange, inputs } = useForm(async (obj) => {
		if (sectionId === 'create') {
			await catalogStore.create_section(catalogId, obj).then(() => {
				navigate(`/catalogs/${catalogId}`);
			});
		} else {
			await catalogStore.update_section(catalogId, sectionId, obj).then(() => {
				navigate(`/catalogs/${catalogId}`);
			});
		}

		return true;
	}, initial);

	if (!section && sectionId !== 'create') {
		return <ActivityIndicator />;
	}

	const initialInput: any = sectionId === 'create' ? defaultSection : section;

	const input = {
		...initialInput,
		...inputs
	};

	return (
		<Container maxWidth="sm">
			<Title>{sectionId === 'create' ? 'Opret sektion' : 'Rediger sektion'}</Title>
			<FormGroup row={false}>
				<FormControl>
					<InputLabel htmlFor="component-simple">Katalog titel</InputLabel>
					<Input id="component-simple" name="title" value={input.title} onChange={handleInputChange} />
				</FormControl>
				<FormControl className={styles.element}>
					<InputLabel htmlFor="component-simple">Fra katalog nummer</InputLabel>
					<Input
						id="component-simple"
						name="fromLot"
						type="number"
						value={input.fromLot}
						onChange={handleInputChange}
					/>
				</FormControl>
			</FormGroup>
			<Divider className={styles.divider} />
			<Button variant="outlined" color="primary" onClick={handleSubmit}>
				Gem ændringer
			</Button>
			<Button
				style={{ float: 'right' }}
				variant="outlined"
				color="secondary"
				onClick={() => {
					catalogStore.delete_section(catalogId, sectionId).then(() => navigate(`/catalogs/${catalogId}`));
				}}
			>
				Slet
			</Button>
		</Container>
	);
});

export default EditCatalogSection;
