import NewsImageModel from './newsImageModel';
import OrderedObject from 'models/interfaces/orderedObject';
import { INewsDto } from '../common/responses/INewsDto';

export default class NewsModel implements OrderedObject {
	id: string;
	order: number;
	title: string;
	text: string;
	images: NewsImageModel[];

	constructor(obj: INewsDto) {
		this.id = obj.newsId;
		this.order = obj.order;
		this.text = obj.text;
		this.title = obj.title;
		if (obj.images) {
			this.images = obj.images.map((x) => new NewsImageModel(x));
			this.images = this.images
				.sort((x, y) => x.order - y.order)
				.map((x, n) => {
					x.order = n;
					return x;
				});
		} else {
			this.images = new Array<NewsImageModel>();
		}
	}
}
