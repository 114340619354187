import * as React from 'react';
import { TextField } from '@mui/material';

interface TextAreaInputProps {
	label: string;
	name: string;
	object: any;
	onChange: (event: any) => void;
}

const TextAreaInput = (props: TextAreaInputProps) => {
	return (
		<TextField
			id={`${props.name}-input`}
			label={props.label}
			margin="normal"
			multiline
			type="text"
			name={props.name}
			value={props.object[props.name] ? props.object[props.name] : ''}
			onChange={props.onChange}
		/>
	);
};

export default TextAreaInput;
