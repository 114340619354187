import * as React from 'react';
import { TableRow, TableCell, Button } from '@mui/material';
import { SourceType, Image } from 'components/image';
import NewsModel from 'models/newsModel';

export interface NewsRowProps {
	firstItem: boolean;
	lastItem: boolean;
	news: NewsModel;
	showNewsDetails: (id: string) => void;
	reorder: (id: string, moveUp: boolean) => void;
}

const NewsRow: React.FunctionComponent<NewsRowProps> = ({ firstItem, lastItem, news, showNewsDetails, reorder }) => {
	return (
		<TableRow key={news.id}>
			<TableCell style={{ verticalAlign: 'top' }}>{news.title}</TableCell>
			<TableCell>{<div dangerouslySetInnerHTML={{ __html: news.text }} />}</TableCell>
			<TableCell>
				{news.images.length > 0 && (
					<Image image={news.images[0].image} width={100} height={100} sourceType={SourceType.news} />
				)}
			</TableCell>
			<TableCell>
				{!firstItem && (
					<Button onClick={() => reorder(news.id, true)} variant="outlined">
						Flyt op
					</Button>
				)}
				{!lastItem && (
					<Button onClick={() => reorder(news.id, false)} variant="outlined">
						Flyt ned
					</Button>
				)}
			</TableCell>
			<TableCell>
				<Button onClick={() => showNewsDetails(news.id)} variant="outlined">
					Detaljer
				</Button>
			</TableCell>
		</TableRow>
	);
};

export default NewsRow;
