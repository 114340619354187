export {}; // to make it a module

declare global {
	// to access the global type
	export interface Array<T> {
		max(callback: (obj: any) => number): any | undefined;
		min(callback: (obj: any) => number): any | undefined;
	}
}

// eslint-disable-next-line no-extend-native
Array.prototype.max = function (callback: (obj: any) => number): any | undefined {
	if (this.length === 0) {
		return undefined;
	}

	if (this.length === 1) {
		return this[0];
	}

	return this.reduce((curr, next) => (callback(curr) > callback(next) ? curr : next));
};

// eslint-disable-next-line no-extend-native
Array.prototype.min = function (callback: (obj: any) => number): any | undefined {
	if (this.length === 0) {
		return undefined;
	}

	if (this.length === 1) {
		return this[0];
	}

	return this.reduce((curr, next) => (callback(curr) < callback(next) ? curr : next));
};
