import { LotImageModel } from './lotImageModel';
import { observable } from 'mobx';

export class LotModel {
	id: string;
	catalogId: number;
	@observable catalogNumber: number;
	@observable creator: string;
	@observable text: string;
	@observable lotNote: string;
	@observable minimumBid: number;
	@observable fullVAT: boolean;
	@observable copyDanRestricted: boolean;
	@observable valuation?: number;
	@observable images: LotImageModel[];
	@observable bidDeadline: Date | undefined;

	constructor(obj: any) {
		this.id = obj.id;
		this.catalogId = obj.catalogId;
		this.catalogNumber = obj.catalogNumber;
		this.creator = obj.creator;
		this.text = obj.text ?? '';
		this.lotNote = obj.note ? obj.note : '';
		this.minimumBid = obj.minimumBid;
		this.fullVAT = obj.fullVAT ? obj.fullVAT : false;
		this.copyDanRestricted = obj.copyDanRestricted ? obj.copyDanRestricted : false;
		this.valuation = obj.valuation;
		this.bidDeadline = obj.bidDeadline ? new Date(obj.bidDeadline) : undefined;
		if (obj.images) {
			const mah: LotImageModel[] = obj.images.map((x: any) => new LotImageModel(x));
			this.images = mah
				.sort((x: LotImageModel, y: LotImageModel) => x.order - y.order)
				.map((x, index) => {
					x.order = index;
					return x;
				});
		} else {
			this.images = new Array<LotImageModel>();
		}
	}
}
