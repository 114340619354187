import { FormControl, Input, InputLabel } from '@mui/material';
import * as React from 'react';

interface TextInputProps {
	label: string;
	name: string;
	object: any;
	onChange: (event: any) => void;
}

const TextInput = (props: TextInputProps) => {
	return (
		<FormControl variant="standard">
			<InputLabel htmlFor={`${props.name}-input`}>{props.label}</InputLabel>
			<Input
				id={`${props.name}-input`}
				type="text"
				name={props.name}
				value={props.object[props.name] ? props.object[props.name] : ''}
				onChange={props.onChange}
			/>
		</FormControl>
	);
};

export default TextInput;
