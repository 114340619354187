import * as React from 'react';
import { Typography, Theme, Card, CardContent, CardMedia } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import currencyFormatter from 'utilities/currency_formatter';
import { navigate } from '@reach/router';
import { IHighestBidderForLotDto } from '../../../common/responses/IHighestBidderForLotDto';
import ImageResource from '../../../utilities/imageResource';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			// padding: theme.spacing(2),
			marginBottom: theme.spacing(3),
			width: '250px'
		},
		phone: {
			paddingLeft: theme.spacing(2)
		},
		divider: {
			marginLeft: -theme.spacing(2),
			marginRight: -theme.spacing(2),
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1)
		}
	})
);

export interface LotBidsProps {
	auctionId: string;
	bid: IHighestBidderForLotDto;
}

const LotBids: React.FunctionComponent<LotBidsProps> = ({ auctionId, bid }) => {
	const styles = useStyles();
	const lot = bid.lot;

	return (
		<Card
			style={{ cursor: 'pointer' }}
			onClick={() => {
				navigate(`/auctions/${auctionId}/online_bids/${bid.lot.catalogId}/${lot.id}`);
			}}
			className={styles.root}
			key={`${lot.id}`}
		>
			<CardContent>
				<Typography variant="h6"># {lot.catalogNumber}</Typography>
			</CardContent>
			{bid.lot.images[0] && (
				<CardMedia
					style={{ height: 140 }}
					image={ImageResource.make(bid.lot.images[0].thumbnail).catalogImageUrl()}
					title={bid.lot.text}
				/>
			)}
			<CardContent>
				<Typography variant="body1">
					Vindende bud: <br />
					<strong>{currencyFormatter(bid.winningBid)}</strong>
				</Typography>
				<Typography variant="body1">
					Max bud: <br />
					<strong>{currencyFormatter(bid.maxBid)}</strong>
				</Typography>
			</CardContent>
		</Card>
	);
};

export default LotBids;
