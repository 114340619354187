import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { RouteComponentProps } from '@reach/router';
import { IBidderDto } from 'common/responses/IBidderDto';
import ActivityIndicator from 'components/activityIndicator';
import Title from 'components/title';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { StoreContext } from 'stores';
import currencyFormatter from 'utilities/currency_formatter';
import TableHeaderLabel from '../../components/headerLabel';
import { truncate } from '../../utilities/truncate';

export interface LatestBidsPageProps extends RouteComponentProps {
	catalogId: string;
}

const LatestBidsPage: React.FunctionComponent<LatestBidsPageProps> = observer((props) => {
	const { catalogStore } = React.useContext(StoreContext);
	const bids = catalogStore.latestBids(props.catalogId, 30);

	if (!bids) {
		return <ActivityIndicator message="Indlæser bud..." />;
	}

	return (
		<React.Fragment>
			<Title>Seneste Bud</Title>
			<Typography variant="body1">
				Siden viser de højeste bud der er blevet lagt, og derved ikke eventuelle mindre bud lagt af samme bruger
				på samme lot.
			</Typography>
			{bids.length === 0 && <Typography variant="body1">Endnu ingen bud</Typography>}
			{bids.length > 0 && (
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>
								<TableHeaderLabel>Lot #</TableHeaderLabel>
							</TableCell>
							<TableCell>
								<TableHeaderLabel>Beskrivelse</TableHeaderLabel>
							</TableCell>
							<TableCell>
								<TableHeaderLabel>Byder</TableHeaderLabel>
							</TableCell>
							<TableCell>
								<TableHeaderLabel>Tidspunkt</TableHeaderLabel>
							</TableCell>
							<TableCell>
								<TableHeaderLabel>Bud</TableHeaderLabel>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{bids.map((x) => (
							<TableRow key={x.bid.consignmentBidId + x.bid.lotId + x.bid.catalogId}>
								<TableCell>{x.lot.catalogNumber}</TableCell>
								<TableCell>{truncate(x.lot.text, 50)}</TableCell>
								<TableCell>{bidderName(x.bid.bidder)}</TableCell>
								<TableCell>{new Date(x.bid.time).toLocaleString()}</TableCell>
								<TableCell>{currencyFormatter(x.bid.bid)}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			)}
		</React.Fragment>
	);
});

function bidderName(bidder: IBidderDto | undefined): string {
	if (!bidder) {
		return 'Bruger Slettet';
	} else if (bidder.onlineUser) {
		if (bidder.onlineUser.companyName) {
			return bidder.onlineUser.companyName;
		} else {
			return `${bidder.onlineUser.firstName} ${bidder.onlineUser.lastName}`;
		}
	} else {
		return 'Offline Bruger';
	}
}

export default LatestBidsPage;
