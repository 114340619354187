import React from 'react';
import Title from 'components/title';
import { RouteComponentProps, navigate } from '@reach/router';
import { useDropzone } from 'react-dropzone';
import { StoreContext } from 'stores/index';
import ImageTable from './components/imageTable';
import { Button } from '@mui/material';
import ActivityIndicator from 'components/activityIndicator';
import { truncate } from 'utilities/truncate';
import { observer } from 'mobx-react-lite';

export interface AddLotImageProps extends RouteComponentProps {
	catalogId: string;
	lotId: string;
}

const AddLotImage: React.FunctionComponent<AddLotImageProps> = observer((props) => {
	const [files, setFiles] = React.useState(new Array<File>());
	const [progress, setProgress] = React.useState(0);
	const [uploading, setUploading] = React.useState(false);
	const { catalogStore } = React.useContext(StoreContext);
	const lot = catalogStore.lot(props.catalogId, props.lotId);

	const onDrop = (acceptedFiles: File[]) => {
		setFiles([...files, ...acceptedFiles]);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	if (!lot) {
		return <ActivityIndicator />;
	}

	if (uploading) {
		return <ActivityIndicator message={`Tilføjer billeder: ${progress}%`} />;
	}

	return (
		<React.Fragment>
			<Title>{`Tilføj billede til lot: ${lot.catalogNumber} - ${truncate(lot.text, 200)}`}</Title>
			<div {...getRootProps()}>
				<input {...getInputProps()} accept="image/*" />
				{isDragActive ? (
					<p>Placer filerne her ...</p>
				) : (
					<p>Træk billederne til lot'et her - ellers tryk for at vælge dem i en dialog</p>
				)}
			</div>

			{files.length > 0 && <ImageTable files={files} setFiles={setFiles} />}

			{files.length > 0 && (
				<Button
					style={{ marginTop: '1em' }}
					variant="outlined"
					onClick={async () => {
						setUploading(true);
						await catalogStore.add_images(props.catalogId, props.lotId, files, (completed, total) => {
							setProgress(Math.round((completed / total) * 100));
						});

						navigate(`/catalogs/${props.catalogId}/lots/${props.lotId}`, { replace: true });
						setUploading(false);
					}}
				>
					Tilføj billeder
				</Button>
			)}
		</React.Fragment>
	);
});

export default AddLotImage;
