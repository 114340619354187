import * as React from 'react';
import Title from 'components/title';
import { RouteComponentProps, navigate } from '@reach/router';
import { Table, TableHead, TableCell, TableRow, TableBody, Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { StoreContext } from 'stores/index';
import { observer } from 'mobx-react-lite';
import NewsModel from '../../models/newsModel';
import NewsRow from './components/newsRow';
import 'utilities/ArrayMinMax';
import ActivityIndicator from 'components/activityIndicator';

const useStyles = makeStyles((theme) => ({
	tableHeader: {
		fontWeight: 'bold'
	}
}));

export interface NewsProps extends RouteComponentProps {}

const News: React.FunctionComponent<NewsProps> = observer(() => {
	const styles = useStyles();
	const { newsStore } = React.useContext(StoreContext);
	const newsModels = newsStore.get_all_news();

	if (!newsModels) {
		return <ActivityIndicator message="Indlæser nyheder..." />;
	}

	const showNewsDetails = (newsId: string) => {
		navigate(`/news/${newsId}`);
	};

	const reorder = (newsId: string, moveUp: boolean) => {
		newsStore.all_news_update_order(newsId, moveUp);
	};

	return (
		<React.Fragment>
			<Title>Nyheder</Title>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							<Typography className={styles.tableHeader} variant="body1">
								Title
							</Typography>
						</TableCell>
						<TableCell>
							<Typography className={styles.tableHeader} variant="body1">
								Text
							</Typography>
						</TableCell>
						<TableCell align="right">
							<Typography className={styles.tableHeader} variant="body1">
								Billede
							</Typography>
						</TableCell>
						<TableCell colSpan={2} align="right">
							<Button variant="outlined" color="primary" onClick={() => showNewsDetails('create')}>
								Opret Nyhed
							</Button>{' '}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{newsModels.map((x: NewsModel, index: number) => (
						<NewsRow
							firstItem={index === 0}
							lastItem={index === newsModels.length - 1}
							key={x.id}
							news={x}
							showNewsDetails={showNewsDetails}
							reorder={reorder}
						/>
					))}
				</TableBody>
			</Table>
		</React.Fragment>
	);
});

export default News;
