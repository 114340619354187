import { LotModel } from './lotModel';

export class LotsSection {
	id: string;
	title: string;
	lots: LotModel[];

	constructor(id: string, title: string, lots: LotModel[]) {
		this.id = id;
		this.title = title;
		this.lots = lots;
	}
}
