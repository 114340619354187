import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Typography
} from '@mui/material';
import { LabelDisplayedRowsArgs } from '@mui/material/TablePagination';
import ActivityIndicator from 'components/activityIndicator';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { StoreContext } from 'stores/index';
import { LotsSection } from '../../../models/lotsSection';
import SectionRowCell from './sectionRowCell';

export interface CatalogLotsProps {
	catalogId: string;
}

const CatalogLots: React.FunctionComponent<CatalogLotsProps> = observer((props) => {
	const itemsPerPage = 30;
	const { catalogStore } = React.useContext(StoreContext);
	const [page, setPage] = React.useState(0);
	const [selectedSection, setSelectedSection] = React.useState<string | undefined>(undefined);
	const lotSections = catalogStore.sectioned_lots(props.catalogId, catalogStore.selectedSection);

	if (!lotSections) {
		return <ActivityIndicator />;
	}

	if (catalogStore.selectedSection !== selectedSection) {
		setSelectedSection(catalogStore.selectedSection);
		setPage(0);
	}

	const startLotNumber = itemsPerPage * page;
	const endLotNumber = itemsPerPage * page + itemsPerPage;
	var lotCounter = 0;
	const shownSections = lotSections.reduce((curr, next) => {
		const filteredLots = next.lots.filter((item, index) => {
			const globalLotIndex = index + lotCounter;
			if (globalLotIndex < startLotNumber) {
				return false;
			}

			if (globalLotIndex > endLotNumber) {
				return false;
			}

			return true;
		});

		if (filteredLots.length > 0) {
			const newSection = new LotsSection(next.id, next.title, filteredLots);
			curr.push(newSection);
		}

		lotCounter = lotCounter + next.lots.length;

		return curr;
	}, new Array<LotsSection>());

	const lotCount = lotSections.flatMap((x) => x.lots).length;

	const handleChangePage = (event: any, newPage: number) => {
		setPage(newPage);
		window.scrollTo(0, 0);
	};

	const labelDisplayedRows = (paginationInfo: LabelDisplayedRowsArgs) => {
		return '';
	};

	return (
		<React.Fragment>
			{shownSections.map((section: LotsSection) => (
				<Paper key={section.id}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell colSpan={3}>
									<Typography style={{ fontWeight: 'bold' }} variant="h6">
										{section.title}
									</Typography>
								</TableCell>
								<TableCell align="right" colSpan={1}></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{section.lots.map((lot) => (
								<SectionRowCell key={lot.id} catalogId={props.catalogId} lot={lot} />
							))}
						</TableBody>
						<TableFooter>
							<TableRow>
								<TablePagination
									rowsPerPageOptions={[50]}
									colSpan={4}
									count={lotCount}
									rowsPerPage={itemsPerPage}
									page={page}
									SelectProps={{
										inputProps: { 'aria-label': 'rows per page' },
										native: true
									}}
									labelDisplayedRows={labelDisplayedRows}
									onPageChange={handleChangePage}
								/>
							</TableRow>
						</TableFooter>
					</Table>
				</Paper>
			))}
		</React.Fragment>
	);
});

export default CatalogLots;
