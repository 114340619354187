import * as React from 'react';
import { FormGroup, InputLabel, Input, Typography, Grid, Button } from '@mui/material';
import TextInput from 'components/form/textInput';
import TextAreaInput from 'components/form/textAreaInput';
import PhoneUser from 'models/phoneUser';

export interface PhoneUserDetailsInfoProps {
	user: PhoneUser;
	handleInputChange: (event: any) => void;
}

const PhoneUserDetailsInfo: React.FunctionComponent<PhoneUserDetailsInfoProps> = (props) => {
	const { user } = props;

	return (
		<Grid container spacing={4}>
			<Grid item xs={4}>
				<Typography gutterBottom variant="h6">
					Kontaktoplysninger
				</Typography>
				<FormGroup>
					<InputLabel htmlFor="name">Navn</InputLabel>
					<FormGroup row={true}>
						<Input id="name" name="firstName" value={user.firstName} onChange={props.handleInputChange} />
						<Input id="name" name="lastName" value={user.lastName} onChange={props.handleInputChange} />
					</FormGroup>
					<TextInput label="Adresse" name="address" object={user} onChange={props.handleInputChange} />
					<TextInput label="Firma navn" name="companyName" object={user} onChange={props.handleInputChange} />
					<TextInput label="Post nummer" name="postalCode" object={user} onChange={props.handleInputChange} />
					<TextInput label="Land" name="country" object={user} onChange={props.handleInputChange} />
					<TextInput
						label="Primær Telefon"
						name="primaryPhone"
						object={user}
						onChange={props.handleInputChange}
					/>
					<TextInput
						label="Sekundær Telefon"
						name="secondaryPhone"
						object={user}
						onChange={props.handleInputChange}
					/>
				</FormGroup>
			</Grid>
			<Grid item>
				<TextAreaInput label="Note" name="note" object={user} onChange={props.handleInputChange} />

				<Typography style={{ marginTop: '1em' }} gutterBottom variant="h6">
					Slet bruger
				</Typography>
				<Button variant="outlined">Slet bruger</Button>
			</Grid>
		</Grid>
	);
};

export default PhoneUserDetailsInfo;
