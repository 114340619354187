export default class ImportedPrice {
	catalogNumber: number;
	combined: boolean;
	price: number;

	constructor(priceRow: string[]) {
		this.catalogNumber = parseInt(priceRow[0]);
		this.combined = priceRow[1] === '+';
		this.price = parseInt(priceRow[2].replace('.', ''));
		if (isNaN(this.price)) {
			this.price = 0;
			if (!this.combined) {
				console.error('NaN price for a non combined row', priceRow);
			}
		}
	}
}
