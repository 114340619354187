import { FormControl, FormHelperText, Input, InputLabel } from '@mui/material';
import * as React from 'react';

export enum InputTypes {
	text = 'text',
	password = 'password'
}

interface TextInputProps {
	autoFocus?: boolean;
	style?: React.CSSProperties;
	customClassName?: string;
	type?: InputTypes;
	errorMessage?: string;
	label: string;
	fieldName: string;
	object: any;
	onChange: (event: any) => void;
}

const TextInput = (props: TextInputProps) => {
	return (
		<FormControl className={props.customClassName} style={props.style} variant="standard">
			<InputLabel htmlFor={`${props.fieldName}-input`}>{props.label}</InputLabel>
			<Input
				error={props.errorMessage ? true : false}
				autoFocus={props.autoFocus ? props.autoFocus : false}
				id={`${props.fieldName}-input`}
				type={props.type ? props.type : 'text'}
				name={props.fieldName}
				value={props.object[props.fieldName] ? props.object[props.fieldName] : ''}
				onChange={props.onChange}
			/>
			<FormHelperText id="component-error-text">{props.errorMessage}</FormHelperText>
		</FormControl>
	);
};

export default TextInput;
