import { Auth } from 'aws-amplify';
import Axios, { AxiosRequestConfig } from 'axios';
import config from 'utilities/config';

export enum ServiceEndpoint {
	Node = 'Node',
	User = 'User',
	Other = 'Other'
}

export default class Network {
	private static url(endpoint: ServiceEndpoint, path: string): string {
		switch (endpoint) {
			case ServiceEndpoint.Node:
				if (path.includes('?')) {
					return `${config().server.path}/${path}&admin=true`;
				} else {
					return `${config().server.path}/${path}?admin=true`;
				}
			case ServiceEndpoint.User:
				return `${config().services.user}/${path}`;
			case ServiceEndpoint.Other:
				return path;
		}
	}

	public static async get<T>(endpoint: ServiceEndpoint, path: string) {
		const session = await Auth.currentSession();
		const accessToken = session.getAccessToken().getJwtToken();

		var axiosConfig: AxiosRequestConfig | undefined;
		if (accessToken) {
			axiosConfig = { headers: { Authorization: accessToken } };
		} else {
			axiosConfig = undefined;
		}

		let serviceUrl = this.url(endpoint, path);

		const result = await Axios.get<T>(serviceUrl, axiosConfig);
		return result.data;
	}

	public static async put<T>(
		endpoint: ServiceEndpoint,
		path: string,
		body: T,
		requiresAuth: boolean = true,
		options: AxiosRequestConfig | undefined = undefined
	) {
		delete Axios.defaults.headers.common['Authorization'];
		let axiosRequestConfig: AxiosRequestConfig = options ?? {};
		if (requiresAuth) {
			const session = await Auth.currentSession();
			const accessToken = session.getAccessToken().getJwtToken();
			if (accessToken) {
				if (axiosRequestConfig.headers) {
					axiosRequestConfig.headers['Authorization'] = accessToken;
				} else {
					axiosRequestConfig.headers = { Authorization: accessToken };
				}
			}
		}

		const result = await Axios.put(this.url(endpoint, path), body, axiosRequestConfig);
		return result;
	}

	public static async post<T>(
		endpoint: ServiceEndpoint,
		path: string,
		body: T,
		requiresAuth: boolean = true,
		options: AxiosRequestConfig | undefined = undefined
	) {
		if (requiresAuth) {
			const session = await Auth.currentSession();
			const accessToken = session.getAccessToken().getJwtToken();
			if (accessToken) {
				Axios.defaults.headers.common['Authorization'] = accessToken;
			}
		}

		const result = await Axios.post(this.url(endpoint, path), body, options);
		return result;
	}

	public static async delete<T>(endpoint: ServiceEndpoint, path: string) {
		const session = await Auth.currentSession();
		const accessToken = session.getAccessToken().getJwtToken();

		var axiosConfig: AxiosRequestConfig | undefined;
		if (accessToken) {
			axiosConfig = { headers: { Authorization: accessToken } };
		} else {
			axiosConfig = undefined;
		}

		let serviceUrl = this.url(endpoint, path);

		const result = await Axios.delete<T>(serviceUrl, axiosConfig);

		return result.data;
	}
}
