import { Button, FormGroup, Grid, Input, InputLabel, Typography } from '@mui/material';
import { navigate } from '@reach/router';
import Network, { ServiceEndpoint } from 'common/network';
import SwitchInput from 'components/form/switchInput';
import TextInput from 'components/form/textInput';
import SureDialog from 'pages/messages/components/sureDialog';
import * as React from 'react';
import { IOnlineUserDto } from '../../../common/responses/IOnlineUserDto';

export interface UserInfoProps {
	bidderId: string;
	user: IOnlineUserDto;
	handleInputChange: (event: any) => void;
}

const UserInfo: React.FunctionComponent<UserInfoProps> = (props) => {
	const { user } = props;
	const [showDialog, setShowDialog] = React.useState(false);

	const handleDelete = async () => {
		await Network.delete(ServiceEndpoint.User, `bidder/${props.bidderId}`);
		navigate('/users', { replace: true });
	};

	return (
		<React.Fragment>
			<Grid container spacing={4}>
				<Grid item xs={4}>
					<Typography gutterBottom variant="h6">
						Kontaktoplysninger
					</Typography>
					<FormGroup>
						<InputLabel htmlFor="name">Navn</InputLabel>
						<FormGroup row={true}>
							<Input
								id="name"
								name="firstName"
								value={user.firstName}
								onChange={props.handleInputChange}
							/>
							<Input id="name" name="lastName" value={user.lastName} onChange={props.handleInputChange} />
						</FormGroup>
						<TextInput
							label="Firma navn"
							name="companyName"
							object={user}
							onChange={props.handleInputChange}
						/>
						<TextInput label="Adresse" name="streetName" object={user} onChange={props.handleInputChange} />
						<TextInput label="Provins" name="province" object={user} onChange={props.handleInputChange} />
						<TextInput
							label="Post nummer"
							name="postalCode"
							object={user}
							onChange={props.handleInputChange}
						/>
						<TextInput label="By" name="city" object={user} onChange={props.handleInputChange} />
						<TextInput label="Land" name="country" object={user} onChange={props.handleInputChange} />
						<TextInput
							label="Telefon nummer"
							name="phoneNumber"
							object={user}
							onChange={props.handleInputChange}
						/>
						<TextInput label="E-mail" name="email" object={user} onChange={props.handleInputChange} />
					</FormGroup>
				</Grid>
				<Grid item>
					<Typography gutterBottom variant="h6">
						Bruger administration
					</Typography>

					<Typography variant="subtitle1">
						Her kan du blokere brugere fra at kunne byde. Brugeren vil stadig kunne se kataloger.
					</Typography>
					<FormGroup>
						<SwitchInput
							label={'Blokeret'}
							name="blocked"
							object={user}
							onChange={props.handleInputChange}
						/>
					</FormGroup>

					{/* <Typography style={{ marginTop: '1em' }} variant="subtitle1">
					// TODO: Tilføj denne funktionalitet: Her kan du resette brugerens password.
				</Typography>
				<Button variant="outlined">Reset Password</Button> */}

					<Button style={{ marginTop: '1em' }} variant="outlined" onClick={() => setShowDialog(true)}>
						Slet bruger
					</Button>
					<Typography style={{ marginTop: '1em' }} variant="subtitle2">
						Vær opmærksom på at brugerens bud ikke slettes. Det er udelukkende personlige data, og bruger
						profilen der forsvinder.
					</Typography>
				</Grid>
			</Grid>

			<SureDialog
				open={showDialog}
				body="Vil du slette brugeren?"
				acceptMessage="Slet"
				handleClose={(perform) => {
					setShowDialog(false);
					if (!perform) {
						return;
					}

					handleDelete();
				}}
			/>
		</React.Fragment>
	);
};

export default UserInfo;
