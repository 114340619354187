import { makeObservable, observable, runInAction } from 'mobx';
import { IStore } from './index';

export enum Severity {
	Information,
	Error
}

export class ToastStore {
	constructor(private rootStore: IStore) {
		makeObservable(this);
	}

	@observable message: String | undefined;

	showMessage(message: String, severity: Severity) {
		runInAction(() => {
			this.message = message;
		});
	}
}
