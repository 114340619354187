import * as _ from 'lodash';
import { action, makeObservable, observable } from 'mobx';
import { IStore } from 'stores';
import Network, { ServiceEndpoint } from './../common/network';
import { IBidderDto } from './../common/responses/IBidderDto';
import { Severity, ToastStore } from './ToastStore';

class UserPage {
	users: IBidderDto[];

	constructor(users: IBidderDto[]) {
		this.users = users;
	}
}

export class OnlineUsersStore {
	toastStore: ToastStore;
	constructor(private rootStore: IStore) {
		makeObservable(this);
		this.toastStore = rootStore.toastStore;
	}

	@observable private totalUserCount: number | undefined;
	@observable private pageCount: number | undefined;
	@observable private paged = new Map<number, UserPage>();
	@observable private pagedSearch = new Map<number, UserPage>();
	@observable private users = new Array<IBidderDto>();
	private searchString: string = '';

	public get_totalUserCount(): number | undefined {
		if (this.totalUserCount) {
			return this.totalUserCount;
		}

		this.fetchPageCount();
	}

	public get_pageCount(): number | undefined {
		if (this.pageCount) {
			return this.pageCount;
		}

		this.fetchPageCount();
	}

	public get_page(pageNumber: number): IBidderDto[] | undefined {
		if (!this.paged.has(pageNumber)) {
			this.fetchPage(pageNumber);
			return undefined;
		}

		return this.paged.get(pageNumber)?.users;
	}

	public get_search_page(searchString: string, pageNumber: number): IBidderDto[] | undefined {
		if (this.searchString !== searchString) {
			this.pagedSearch.clear();
			this.searchString = searchString;
		}

		if (!this.pagedSearch.has(pageNumber)) {
			this.fetchSearchPage(searchString, pageNumber);
			return undefined;
		}

		return this.pagedSearch.get(pageNumber)?.users;
	}

	public get_user(userId: string): IBidderDto | undefined {
		const user = this.users.find((x) => x.id === userId);
		if (!user) {
			this.fetchUser(userId);
			return undefined;
		}

		return user;
	}

	@action public async fetchSearchPage(searchString: string, pageNumber: number) {
		try {
			const users = await Network.get<IBidderDto[]>(
				ServiceEndpoint.User,
				`bidders/search?search=${searchString}&pageNumber=${pageNumber}`
			);
			const usersPage = new UserPage(users);
			this.pagedSearch.set(pageNumber, usersPage);
		} catch (ex: any) {
			this.errorHandler(ex);
		}
	}

	@action private async fetchPageCount() {
		const pageSize = 20;

		type UserCount = {
			count: number;
			pageCount: number;
		};

		try {
			const userCountResult = await Network.get<UserCount>(ServiceEndpoint.User, 'count');
			this.totalUserCount = userCountResult.count;
			this.pageCount = Math.ceil(this.totalUserCount / pageSize);
		} catch (error) {
			console.error(error);
		}
	}

	@action private async fetchPage(pageNumber: number) {
		try {
			const userPageRaw = await Network.get<IBidderDto[]>(
				ServiceEndpoint.User,
				`bidders?page=${pageNumber}&pageSize=20`
			);
			const usersPage = new UserPage(userPageRaw);
			this.paged.set(pageNumber, usersPage);
		} catch (ex: any) {
			this.errorHandler(ex);
		}
	}

	@action
	async update_user(bidderId: string, changed: any) {
		try {
			await Network.put(ServiceEndpoint.User, `bidder/${bidderId}`, changed);
			this.toastStore.showMessage('Bruger opdateret', Severity.Information);
		} catch (ex: any) {
			this.errorHandler(ex);
		}

		if (this.paged) {
			const users = Array.from(this.paged.values()).flatMap((x) => x.users);
			const user = users.find((user) => user.id === bidderId);

			if (!user) {
				console.error('Failed to find user');
			} else {
				_.merge(user, changed);
			}
		}
	}

	@action
	async fetchUser(userId: string) {
		try {
			const bidder = await Network.get<IBidderDto>(ServiceEndpoint.User, `bidder/${userId}`);
			this.users.push(bidder);
		} catch (ex: any) {
			this.errorHandler(ex);
		}
	}

	errorHandler = (ex: any) => {
		console.error(ex);
		if (ex.response) {
			this.toastStore.showMessage(ex.response.data, Severity.Error);
		} else {
			this.toastStore.showMessage(ex.message, Severity.Error);
		}
	};
}
