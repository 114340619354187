import React from 'react';
import { Table, TableHead, TableCell, TableRow, TableBody } from '@mui/material';
import AddLotImageRow from './addLotImageRow';
import TableHeaderLabel from 'components/headerLabel';

export interface ImageTableProps {
	files: File[];
	setFiles: (files: File[]) => void;
}

const ImageTable: React.FunctionComponent<ImageTableProps> = (props) => {
	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>
						<TableHeaderLabel>File Name</TableHeaderLabel>
					</TableCell>
					<TableCell>
						<TableHeaderLabel>Type</TableHeaderLabel>
					</TableCell>
					<TableCell></TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{props.files.map((file) => (
					<AddLotImageRow
						key={file.name}
						file={file}
						onDelete={(file: File) => {
							const newFiles = props.files.filter((x) => file.name !== x.name);
							props.setFiles(newFiles);
						}}
					/>
				))}
			</TableBody>
		</Table>
	);
};

export default ImageTable;
