import ImageResource from '../utilities/imageResource';
import RowImage from './interfaces/rowImage';
import { observable } from 'mobx';

export class LotImageModel implements RowImage {
	id: string;
	lotId: number;
	@observable order: number;
	@observable image: ImageResource;
	@observable thumbnail: ImageResource;

	constructor(obj: any) {
		this.id = obj.id;
		this.lotId = obj.lotId;
		this.order = obj.order;
		this.image = new ImageResource(obj.image.path, obj.image.imageWidth, obj.image.imageHeight);
		this.thumbnail = new ImageResource(obj.thumbnail.path, obj.thumbnail.imageWidth, obj.thumbnail.imageHeight);
	}
}
