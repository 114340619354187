import * as React from 'react';
import RichTextEditor, { EditorValue } from 'react-rte';
import { TextField, Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		contentView: {
			width: '100%'
		},
		richTextView: {
			marginTop: '1em'
		},
		editorRichTextView: {
			height: '20em'
		}
	})
);

export interface MessageInputAreaProps {
	subject: string;
	body: EditorValue;
	setSubject: (subject: string) => void;
	setBody: (body: EditorValue) => void;
}

const MessageInputArea: React.FunctionComponent<MessageInputAreaProps> = (props) => {
	const styles = useStyles();

	return (
		<div>
			<TextField
				className={styles.contentView}
				id="outlined-basic"
				label="Emne"
				margin="none"
				variant="outlined"
				value={props.subject}
				onChange={(x) => props.setSubject(x.currentTarget.value)}
			/>
			<div>
				<RichTextEditor
					className={styles.richTextView}
					editorClassName={styles.editorRichTextView}
					value={props.body}
					onChange={(event: EditorValue) => {
						props.setBody(event);
					}}
				/>
			</div>
		</div>
	);
};

export default MessageInputArea;
