import _ from 'lodash';
import defaultSettings from './default.json';
import dev from './dev.json';
import prod from './production.json';

const settings = () => {
	switch ("prod") {
		case 'dev':
			return _.merge(defaultSettings, dev);
		case 'prod':
			return _.merge(defaultSettings, prod);
		default:
			break;
	}

	return defaultSettings;
};

export default settings;
