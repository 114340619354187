import * as React from 'react';
import TableHeaderLabel from 'components/headerLabel';
import { TableCell, TableRow, TextField } from '@mui/material';

export interface PagedUserHeaderProps {
	searchString: string;
	onSearch: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const PagedUserHeader: React.FunctionComponent<PagedUserHeaderProps> = (props) => {
	return (
		<TableRow>
			<TableCell>
				<TableHeaderLabel>Fornavn</TableHeaderLabel>
			</TableCell>
			<TableCell>
				<TableHeaderLabel>Efternavn</TableHeaderLabel>
			</TableCell>
			<TableCell>
				<TableHeaderLabel>Firma</TableHeaderLabel>
			</TableCell>
			<TableCell>
				<TableHeaderLabel>Adresse</TableHeaderLabel>
			</TableCell>
			<TableCell>
				<TableHeaderLabel>Post</TableHeaderLabel>
			</TableCell>
			<TableCell>
				<TableHeaderLabel>Land</TableHeaderLabel>
			</TableCell>
			<TableCell>
				<TableHeaderLabel>Blokeret</TableHeaderLabel>
			</TableCell>
			<TableCell align="right">
				<TextField
					value={props.searchString}
					onChange={props.onSearch}
					id="standard-search"
					label="Søg"
					type="search"
					margin="dense"
				/>
			</TableCell>
		</TableRow>
	);
};

export default PagedUserHeader;
