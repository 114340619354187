import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { navigate, RouteComponentProps } from '@reach/router';
import Network, { ServiceEndpoint } from 'common/network';
import { IBidDetailsDto } from 'common/responses/IBidDetailsDto';
import { IConsignmentBidDto } from 'common/responses/IConsignmentBidDto';
import ActivityIndicator from 'components/activityIndicator';
import CatalogImage from 'components/catalogImage';
import TableHeaderLabel from 'components/headerLabel';
import Title from 'components/title';
import * as React from 'react';
import config from 'utilities/config';
import currencyFormatter from 'utilities/currency_formatter';
import ImageResource from 'utilities/imageResource';

export interface ConsignmentBidsPageProps extends RouteComponentProps {
	auctionId: string;
	catalogId: string;
	lotId: string;
}

const ConsignmentBidsPage: React.FunctionComponent<ConsignmentBidsPageProps> = (props) => {
	const [bidDetails, setBidDetails] = React.useState<IBidDetailsDto | undefined>();

	Network.get<IBidDetailsDto>(
		ServiceEndpoint.Node,
		`catalogs/${props.catalogId}/lots/${props.lotId}/bids/details`
	).then((x) => setBidDetails(x));

	if (!bidDetails) {
		return <ActivityIndicator />;
	}

	const enableOrangeBid = config().features.enableOrangeBid;

	return (
		<React.Fragment>
			<Grid container spacing={3}>
				<Grid item xs={4}>
					<Title>{bidDetails.lot.catalogNumber}</Title>
					{bidDetails.lot.text}
					<div style={{ marginTop: '1em' }}>
						{bidDetails.lot.images && bidDetails.lot.images.length > 0 && (
							<CatalogImage image={ImageResource.make(bidDetails.lot.images[0].thumbnail)} height={200} />
						)}
					</div>
				</Grid>
				<Grid item xs={8}>
					<Title>Kommissionsbud</Title>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									<TableHeaderLabel>Tid</TableHeaderLabel>
								</TableCell>
								<TableCell>
									<TableHeaderLabel>Byder</TableHeaderLabel>
								</TableCell>
								<TableCell>
									<TableHeaderLabel>Beløb</TableHeaderLabel>
								</TableCell>
								<TableCell />
							</TableRow>
						</TableHead>
						<TableBody>
							{bidDetails.bids
								.slice()
								.sort((x: IConsignmentBidDto, y: IConsignmentBidDto) => y.bid - x.bid)
								.map((b) => {
									const bidder = b.bidder;
									let bidderName = '';
									if (bidder && bidder.offlineUser) {
										bidderName = bidder.offlineUser.note;
									} else if (bidder && bidder.onlineUser) {
										const onlineUser = bidder.onlineUser;
										if (onlineUser.companyName && onlineUser.firstName) {
											bidderName = `${onlineUser.companyName} ved ${onlineUser.firstName} ${
												onlineUser.lastName ? onlineUser.lastName : ''
											}`;
										} else {
											bidderName = `${onlineUser.firstName} ${onlineUser.lastName}`;
										}
									}

									return (
										<TableRow key={b.consignmentBidId}>
											<TableCell>{new Date(b.time).toLocaleString()}</TableCell>
											<TableCell>{bidderName}</TableCell>
											<TableCell>{currencyFormatter(b.bid)}</TableCell>
											<TableCell>
												<Button
													onClick={() => {
														navigate(
															`/auctions/${props.auctionId}/online_bids/${props.catalogId}/${props.lotId}/${b.consignmentBidId}`
														);
													}}
												>
													Rediger
												</Button>
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					</Table>

					{enableOrangeBid && (
						<Button
							variant="outlined"
							style={{ marginTop: '1em' }}
							color="primary"
							onClick={() => {
								navigate(`/orange_bids/${props.catalogId}/${props.lotId}`);
							}}
						>
							Læg orange bud
						</Button>
					)}
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

export default ConsignmentBidsPage;
