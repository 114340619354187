import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar } from '@mui/material';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { StoreContext } from 'stores';

export interface ToastProps {}

const Toast: React.FunctionComponent<ToastProps> = observer(() => {
	const { toastStore } = React.useContext(StoreContext);

	const message = toastStore.message;

	const onDismiss = () => {
		runInAction(() => {
			toastStore.message = undefined;
		});
	};

	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left'
			}}
			open={toastStore.message !== undefined}
			autoHideDuration={6000}
			onClose={onDismiss}
			ContentProps={{
				'aria-describedby': 'message-id'
			}}
			message={<span id="message-id">{message}</span>}
			action={[
				<IconButton key="close" aria-label="close" color="inherit" onClick={onDismiss} size="large">
					<CloseIcon />
				</IconButton>
			]}
		/>
	);
});

export default Toast;
