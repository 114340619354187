import * as React from 'react';
import { FormControl, InputLabel, Input } from '@mui/material';

interface NumberInputProps {
	label: string;
	name: string;
	object: any;
	style?: React.CSSProperties;
	onChange: (event: any) => void;
}

const NumberInput = (props: NumberInputProps) => {
	return (
		<FormControl style={props.style}>
			<InputLabel htmlFor={`${props.name}-input`}>{props.label}</InputLabel>
			<Input
				id={`${props.name}-input`}
				type="number"
				name={props.name}
				value={props.object[props.name] ? props.object[props.name] : 0}
				onChange={props.onChange}
			/>
		</FormControl>
	);
};

export default NumberInput;
