import { ICreateSalesDto } from './ICreateSalesDto';
import { ISaleDto } from './ISaleDto';
import ImportedPrice from '../../pages/importPrices/models/importedPrice';
import { SaleDto } from './SaleDto';
export class CreateSalesDto implements ICreateSalesDto {
	auctionId: string;
	sales: ISaleDto[];
	constructor(importedPrices: ImportedPrice[], auctionId: string) {
		this.auctionId = auctionId;
		let currentLots: ImportedPrice[] = [];
		let sales: ISaleDto[] = [];
		for (const key in importedPrices) {
			if (!importedPrices.hasOwnProperty(key)) {
				continue;
			}
			const element = importedPrices[key];
			currentLots.push(element);
			if (element.combined) {
				continue;
			} else {
				const sale = new SaleDto(currentLots);
				sales.push(sale);
				currentLots = new Array<ImportedPrice>();
			}
		}
		this.sales = sales;
	}
}
