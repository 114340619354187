export class ParseError implements Error {
	name: string;

	constructor(public message: string, public critical: boolean) {
		this.name = 'ParseError';
	}
}

export class ImportedLot {
	catalogNumber: number;
	fullVAT: boolean;
	creator?: string;
	copyDanRestricted: boolean;
	text: string;
	lotNote?: string;
	valuation?: number;
	bidDeadline: Date | undefined;

	static make(fields: string[]): ImportedLot | undefined {
		if (fields.length === 1 && fields[0] === '') {
			console.warn('[Advarsel] Fandt tom linie i katalog fil - den ignoreres');
			return undefined;
		}

		if (fields.length !== 6) {
			throw new ParseError(
				`[Fejl] En række i katalog filen har mindre end 6 felter (${fields.length}) hvilket ikke er tilladt: '${fields}'`,
				true
			);
		}

		return new ImportedLot(fields);
	}

	private constructor(fields: string[]) {
		this.catalogNumber = parseInt(fields[0]);
		this.fullVAT = fields[1] === '*';
		this.creator = fields[2].length > 0 ? fields[2] : undefined;
		this.copyDanRestricted = fields[3] === '/CD';
		this.text = fields[4].trim().replace(new RegExp('\\s+', 'g'), ' ');

		const match = this.text.match(/Bemærk.*/);
		if (match && match.length > 0) {
			this.lotNote = match[0];
			this.text = this.text.replace(this.lotNote, '');
		}

		const rawValuation = fields[5]
			.replace('[', '') // remove [
			.replace(']', '') // remove ]
			.replace(/\./g, ''); // remove . e.g. 40.000;

		// some valuations are in the format 4-5000
		const upperValuation = rawValuation.split('-').pop();
		this.valuation = upperValuation ? parseInt(upperValuation) : undefined;
	}

	setTimeDifference(auctionDate: Date | undefined, timeDifference: number) {
		if (auctionDate) {
			this.bidDeadline = addMinutes(auctionDate, timeDifference);
		} else {
		}
	}
}

function addMinutes(date: Date, minutes: number): Date {
	return new Date(date.getTime() + minutes * 60000);
}
