import OrderedObject from 'models/interfaces/orderedObject';
import { Severity, ToastStore } from 'stores/ToastStore';

const update_order = async (
	toastStore: ToastStore,
	items: OrderedObject[],
	reorderItemId: string,
	moveUp: boolean,
	updateOrder: (ordering: string[]) => Promise<void>,
	applyChange: (items: OrderedObject[]) => void
) => {
	const currentItem = items.find((x) => x.id === reorderItemId);
	if (!currentItem) {
		console.error('Failed to find item');
		return;
	}

	let other: OrderedObject;
	if (moveUp) {
		other = items.filter((x) => x.order < currentItem.order).max((x) => x.order);
	} else {
		other = items.filter((x) => x.order > currentItem.order).min((x) => x.order);
	}

	if (!other) {
		console.error('Failed to find another image to switch');
		return;
	}

	const swapOrder = (item1: OrderedObject, item2: OrderedObject) => {
		const existingOrder = item1.order;
		item1.order = item2.order;
		item2.order = existingOrder;
	};

	swapOrder(currentItem, other);

	const newItems = items.sort((x, y) => x.order - y.order);
	applyChange(newItems);

	const order = newItems.map((x) => x.id);

	try {
		await updateOrder(order);
		toastStore.showMessage('Rækkefølge opdateret', Severity.Information);
	} catch (ex: any) {
		if (ex.response) {
			toastStore.showMessage(`Kunne ikke opdatere rækkefølgen: ${ex.response.message}`, Severity.Information);
		} else {
			toastStore.showMessage(`Kunne ikke opdatere rækkefølgen: ${ex.message}`, Severity.Information);
		}

		console.error('failed to reorder');
		// revert change
		swapOrder(currentItem, other);
		const newerItems = newItems.sort((x, y) => x.order - y.order);
		applyChange(newerItems);
	}
};

export default update_order;
