import ImageResource from '../utilities/imageResource';
import RowImage from './interfaces/rowImage';
import { INewsImageDto } from '../common/responses/INewsImageDto';

export default class NewsImageModel implements RowImage {
	id: string;
	newsImageId: string;
	newsId: string;
	order: number;
	image: ImageResource;
	thumbnail: ImageResource;

	constructor(obj: INewsImageDto) {
		this.id = obj.newsImageId;
		this.newsImageId = obj.newsImageId;
		this.newsId = obj.newsId;
		this.order = obj.order;
		this.image = new ImageResource(obj.path, obj.imageWidth, obj.imageHeight);
		this.thumbnail = new ImageResource(obj.path, obj.imageWidth, obj.imageHeight);
	}
}
