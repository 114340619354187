import Network, { ServiceEndpoint } from 'common/network';
import { IAuctionDto } from 'common/responses/IAuctionDto';
import { action, makeObservable, observable, runInAction } from 'mobx';
import AuctionModel from 'models/auctionModel';
import { IAuctionBiddingStatusDto } from '../common/responses/IAuctionBiddingStatusDto';
import { IStore } from './index';
import { Severity, ToastStore } from './ToastStore';

export class AuctionsStore {
	toastStore: ToastStore;
	constructor(private rootStore: IStore) {
		makeObservable(this);
		this.toastStore = rootStore.toastStore;
	}

	@observable private activeAuctions: AuctionModel[] | undefined;
	@observable private paged = new Map<number, AuctionModel[]>();
	@observable private biddingStatus = new Map<string, IAuctionBiddingStatusDto>();
	@observable private auctions = new Array<AuctionModel>();
	@observable private totalAuctionCount: number | undefined;
	@observable private pageCount: number | undefined;

	public get_pageCount(): number | undefined {
		if (this.pageCount) {
			return this.pageCount;
		}

		this.fetchPageCount();
	}

	public get_totalAuctionCount(): number | undefined {
		if (this.totalAuctionCount) {
			return this.totalAuctionCount;
		}

		this.fetchPageCount();
	}

	public get_page(pageNumber: number): AuctionModel[] | undefined {
		if (!this.paged.has(pageNumber)) {
			this.fetchPage(pageNumber);
			return undefined;
		}

		return this.paged.get(pageNumber);
	}

	public get_auction(auctionId: string): AuctionModel | undefined {
		const auction = this.auctions.find((x) => x.id === auctionId);
		if (auction) {
			return auction;
		}

		this.fetchAuction(auctionId);
		return undefined;
	}

	public async create_auction(auctionObject: AuctionModel) {
		const obj = auctionObject as any;
		try {
			delete obj.id;
			delete obj.catalogId;
			await Network.post(ServiceEndpoint.Node, 'auctions', obj);
			this.paged.clear();
			this.auctions = new Array<AuctionModel>();
			this.toastStore.showMessage('Auktion oprettet', Severity.Information);
		} catch (ex: any) {
			this.errorHandler(ex);
		}
	}

	@action
	public async delete_auction(auctionId: string) {
		const newPaged = new Map<number, AuctionModel[]>();
		Array.from(this.paged.entries()).forEach(([page, list]) => {
			newPaged.set(
				page,
				list.filter((x) => x.id !== auctionId)
			);
		});
		this.paged = newPaged;

		await Network.delete(ServiceEndpoint.Node, `auctions/${auctionId}`);
	}

	public async update_auction(id: string, auctionObject: AuctionModel) {
		try {
			await Network.put(ServiceEndpoint.Node, `auctions/${id}`, auctionObject, true);
			this.toastStore.showMessage('Auktion opdateret', Severity.Information);
			await this.fetchAuction(id);
			this.paged.forEach((page) => {
				page.forEach((auction, index) => {
					if (auction.id === id) {
						page[index] = { ...auction, ...auctionObject };
					}
				});
			});
		} catch (ex: any) {
			this.errorHandler(ex);
		}
	}

	active_auctions(): AuctionModel[] | undefined {
		const activeAuctions = this.activeAuctions;
		if (!activeAuctions) {
			this.fetchActiveAuctions();
			return undefined;
		} else {
			return activeAuctions;
		}
	}

	get_bidding_status(auctionId: string): IAuctionBiddingStatusDto | undefined {
		const biddingStatus = this.biddingStatus.get(auctionId);
		if (!biddingStatus) {
			this.fetchBiddingStatus(auctionId);
		}

		return biddingStatus;
	}

	@action private async fetchBiddingStatus(auctionId: string) {
		const _biddingStatus: IAuctionBiddingStatusDto = await Network.get<IAuctionBiddingStatusDto>(
			ServiceEndpoint.Node,
			`auctions/${auctionId}/biddingStatus`
		);

		this.biddingStatus.set(auctionId, _biddingStatus);
	}

	@action private async fetchAuction(auctionId: string) {
		const auction = await Network.get<IAuctionDto>(ServiceEndpoint.Node, `auctions/${auctionId}`).then(
			(x) => new AuctionModel(x)
		);
		runInAction(() => {
			this.auctions = this.auctions.filter((x) => x.id !== auction.id).concat([auction]);
		});
	}

	private async fetchPageCount() {
		type PageCountResponse = {
			pageCount: number;
			count: number;
		};

		const pageCount = await Network.get<PageCountResponse>(ServiceEndpoint.Node, `auctions/count?pageSize=20`);
		runInAction(() => {
			this.pageCount = pageCount.pageCount;
			this.totalAuctionCount = pageCount.count;
		});
	}

	@action private async fetchPage(pageNumber: number) {
		const auctions = await Network.get<IAuctionDto[]>(
			ServiceEndpoint.Node,
			`auctions?page=${pageNumber}&pageSize=20`
		).then((x) => x.map((x: any) => new AuctionModel(x)));

		this.paged.set(pageNumber, auctions);
	}

	@action private async fetchActiveAuctions() {
		const auctions = await Network.get<IAuctionDto[]>(
			ServiceEndpoint.Node,
			`auctions/active?page=1&pageSize=200`
		).then((x) => x.map((x: any) => new AuctionModel(x)));

		runInAction(() => {
			this.activeAuctions = auctions;
		});
	}

	@action clear() {
		this.auctions = [];
		this.activeAuctions = [];
		this.paged.clear();
	}

	errorHandler = (ex: any) => {
		console.error(ex);
		if (ex.response) {
			this.toastStore.showMessage(ex.response.data, Severity.Error);
		} else if (ex.message) {
			this.toastStore.showMessage(ex.message, Severity.Error);
		} else if (ex) {
			this.toastStore.showMessage(ex, Severity.Error);
		}
	};
}
