import React from 'react';
import ImageResource from '../utilities/imageResource';

interface ImageProps {
	mode?: ImageModes;
	height?: number;
	width?: number;
	sourceType: SourceType;
	image: ImageResource;
}

export enum SourceType {
	news = 'news',
	catalog = 'catalog'
}

export enum ImageModes {
	fill = 'cover',
	fit = 'contain'
}

export const Image: React.FunctionComponent<ImageProps> = (props) => {
	let { image, height, width } = props;

	let size: string = props.mode || 'contain';

	let calcWidth = 0,
		calcHeight = 0;
	if (height) {
		calcHeight = height;
		calcWidth = (image.width / image.height) * height;
	}

	if (width) {
		calcWidth = width;
		calcHeight = (image.height / image.width) * width;
	}

	// if (height && calcWidth < calcHeight) {
	// 	calcHeight = height;
	// } else {
	// 	calcWidth = width;
	// }

	let defaults = {
		height: calcHeight,
		width: calcWidth,
		backgroundColor: 'clear'
	};

	let url: string = '';
	switch (props.sourceType) {
		case SourceType.news:
			url = image.newsImageUrl();
			break;
		case SourceType.catalog:
			url = image.catalogImageUrl();
			break;
	}

	let important = {
		backgroundImage: `url("${url}")`,
		backgroundSize: size,
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat'
	};

	return <div style={{ ...defaults, ...important }} />;
};
