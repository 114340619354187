import { action, observable, runInAction } from 'mobx';
import { IStore } from 'stores';
import Network, { ServiceEndpoint } from '../common/network';
import { Severity, ToastStore } from './ToastStore';

interface MailTemplate {
	id: string;
	name: string;
	subject: string;
	body: string;
}

export class MailTemplatesStore {
	toastStore: ToastStore;

	@observable templates: MailTemplate[] | undefined;

	constructor(private rootStore: IStore) {
		this.toastStore = rootStore.toastStore;
	}

	get_templates(): MailTemplate[] | undefined {
		if (this.templates) {
			return this.templates;
		}

		this.fetchTemplates();
	}

	@action async fetchTemplates() {
		const templates = await Network.get<MailTemplate[]>(ServiceEndpoint.Node, '/messages/templates');
		runInAction(() => (this.templates = templates));
	}

	errorHandler = (ex: any) => {
		console.error(ex);
		if (ex.response) {
			this.toastStore.showMessage(ex.response.data, Severity.Error);
		} else {
			this.toastStore.showMessage(ex.message, Severity.Error);
		}
	};
}
