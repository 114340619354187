import { observable } from 'mobx';
import { ICatalogDto } from '../common/responses/ICatalogDto';
import AuctionModel from './auctionModel';
import { CatalogSectionModel } from './catalogSectionModel';
import { LotModel } from './lotModel';

export class CatalogModel {
	id: string;
	@observable published: boolean;
	@observable sections: CatalogSectionModel[];
	auction: AuctionModel;
	@observable lots: LotModel[];

	constructor(obj: ICatalogDto) {
		this.id = obj.catalogId;
		this.published = obj.published ?? false;
		this.sections = obj.sections
			.map((x: any) => new CatalogSectionModel(x))
			.sort((x: CatalogSectionModel, y: CatalogSectionModel) => x.fromLot - y.fromLot);
		this.auction = new AuctionModel(obj.auction);
		const mappedLots = obj.lots.map((x: any) => new LotModel(x)) as LotModel[];
		this.lots = mappedLots.sort((x, y) => x.catalogNumber - y.catalogNumber);
	}

	lotsForSection(sectionId: string): LotModel[] {
		const section = this.sections.find((x) => x.id === sectionId);
		if (!section) {
			return [];
		}

		let batch = this.lots.filter((x) => x.catalogNumber >= section.fromLot);

		const nextSections = this.sections.filter((x) => x.fromLot > section.fromLot);

		if (nextSections.length > 0) {
			const nextSection = nextSections.reduce((curr, next) => (curr.fromLot < next.fromLot ? curr : next));
			if (nextSection) {
				batch = batch.filter((x) => x.catalogNumber < nextSection.fromLot);
			}
		}

		return batch;
	}
}
