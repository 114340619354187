import * as React from 'react';
import { Table, TableBody } from '@mui/material';
import OrderedImagePresentationRow from './orderedImagePresentationTableRow';
import RowImage from 'models/interfaces/rowImage';
import { observer } from 'mobx-react-lite';

export interface OrderedImagePresentationProps {
	images: RowImage[];
	baseUrl: string;
	isCatalog: boolean;
	onReorder: (itemId: string, up: boolean) => void;
	onDelete: (image: RowImage) => void;
}

const OrderedImagePresentationTable: React.FunctionComponent<OrderedImagePresentationProps> = observer((props) => {
	return (
		<Table>
			<TableBody>
				{props.images
					.slice()
					.sort((x, y) => x.order - y.order)
					.map((image) => (
						<OrderedImagePresentationRow
							isCatalog={props.isCatalog}
							key={image.id}
							images={props.images}
							image={image}
							baseUrl={props.baseUrl}
							onDelete={props.onDelete}
							onReorder={props.onReorder}
						/>
					))}
			</TableBody>
		</Table>
	);
});

export default OrderedImagePresentationTable;
