import { Button, Container, Grid, Link, TextField, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { navigate, RouteComponentProps } from '@reach/router';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { StoreContext } from 'stores/index';

export interface LogonProps extends RouteComponentProps {}

const useStyles = makeStyles((theme: Theme) => ({
	bottomButtons: {
		marginTop: theme.spacing(1)
	}
}));

const Logon: React.FunctionComponent<LogonProps> = observer(() => {
	const { userStore } = React.useContext(StoreContext);
	const [username, setUsername] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [error, setError] = React.useState('');
	const styles = useStyles();

	if (userStore.isAuthenticated) {
		navigate('/');
		return <React.Fragment />;
	}

	const handleLogon = async (event: React.SyntheticEvent) => {
		event.preventDefault();
		try {
			await userStore.login(username, password);
			navigate('/auctions');
		} catch (ex: any) {
			setError(ex.message);
			console.debug(ex);
		}
	};

	return (
		<React.Fragment>
			<Container maxWidth="xs">
				<Typography variant="h5">Log på</Typography>
				<form noValidate onSubmit={handleLogon}>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Adresse"
						name="email"
						autoComplete="email"
						onChange={(e: any) => {
							setUsername(e.currentTarget.value);
						}}
						autoFocus
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Kodeord"
						type="password"
						id="password"
						onChange={(e: any) => {
							setPassword(e.currentTarget.value);
						}}
						autoComplete="current-password"
					/>
					{/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}
					<Button type="submit" fullWidth variant="contained" color="primary">
						Log På
					</Button>
					<Grid container className={styles.bottomButtons}>
						<Grid item xs>
							<Link href="#" variant="body2">
								Glemt kodeord?
							</Link>
						</Grid>
						<Grid item>
							<Link href="#" variant="body2">
								Opret konto!
							</Link>
						</Grid>
					</Grid>
					{error && (
						<Typography variant="body2" color="error">
							{error}
						</Typography>
					)}
				</form>
			</Container>
		</React.Fragment>
	);
});

export default Logon;
