import { Paper, Table, TableBody, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { LabelDisplayedRowsArgs } from '@mui/material/TablePagination';
import { RouteComponentProps } from '@reach/router';
import { IBidderDto } from 'common/responses/IBidderDto';
import ActivityIndicator from 'components/activityIndicator';
import Title from 'components/title';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { StoreContext } from 'stores';
import PagedUserHeader from './components/pagedUserHeader';
import PagedUserRow from './components/pagedUserRow';

export interface OnlineBiddersProps extends RouteComponentProps {}

const OnlineBidder: React.FunctionComponent<OnlineBiddersProps> = observer(() => {
	const pageSize = 20;
	const [page, setPage] = React.useState<number>(0);
	const [searchQuery, setSearchQuery] = React.useState<string>('');
	const { onlineUsersStore } = React.useContext(StoreContext);

	const pageCount = onlineUsersStore.get_pageCount();
	if (!pageCount) {
		return <ActivityIndicator message={'Indlæser brugere.'} />;
	}

	const totalUserCount = onlineUsersStore.get_totalUserCount();
	if (!totalUserCount) {
		return <ActivityIndicator message={'Indlæser brugere..'} />;
	}

	let pageElements: IBidderDto[] | undefined;
	if (searchQuery.length === 0) {
		pageElements = onlineUsersStore.get_page(page + 1);
	} else {
		pageElements = onlineUsersStore.get_search_page(searchQuery, page + 1);
	}

	if (!pageElements) {
		if (searchQuery.length > 0) {
			pageElements = new Array<IBidderDto>();
		} else {
			return <ActivityIndicator message={'Indlæser brugere...'} />;
		}
	}

	const onSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const search = event.currentTarget.value;
		setPage(0);
		setSearchQuery(search);
	};

	const handleChangePage = (event: any, newPage: number) => {
		setPage(newPage);
		window.scrollTo(0, 0);
	};

	const labelDisplayedRows = (paginationInfo: LabelDisplayedRowsArgs) => {
		return '';
	};

	return (
		<React.Fragment>
			<Title>Brugere</Title>
			<Paper>
				<Table stickyHeader>
					<TableHead>
						<PagedUserHeader searchString={searchQuery} onSearch={onSearch} />
					</TableHead>
					<TableBody>
						{pageElements.map((x) => (
							<PagedUserRow key={x.id} user={x} />
						))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[5]}
								colSpan={8}
								count={totalUserCount}
								rowsPerPage={pageSize}
								page={page}
								SelectProps={{
									inputProps: { 'aria-label': 'rows per page' },
									native: true
								}}
								labelDisplayedRows={labelDisplayedRows}
								onPageChange={handleChangePage}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</Paper>
		</React.Fragment>
	);
});

export default OnlineBidder;
