import React from 'react';
import { Table, TableHead, TableCell, TableRow, TableBody } from '@mui/material';
import ImportImageRow from './importImageRow';
import TableHeaderLabel from 'components/headerLabel';
import { CatalogImageWrap } from '../models/CatalogImageWrap';

export interface ImageTableProps {
	files: CatalogImageWrap[];
	setFiles: (files: CatalogImageWrap[]) => void;
}

const ImageTable: React.FunctionComponent<ImageTableProps> = (props) => {
	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>
						<TableHeaderLabel>Image</TableHeaderLabel>
					</TableCell>
					<TableCell>
						<TableHeaderLabel>File Name</TableHeaderLabel>
					</TableCell>
					<TableCell>
						<TableHeaderLabel>Catalog #</TableHeaderLabel>
					</TableCell>
					<TableCell>
						<TableHeaderLabel>Order</TableHeaderLabel>
					</TableCell>
					<TableCell></TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{props.files.map((file) => (
					<ImportImageRow
						key={file.name}
						file={file}
						onDelete={(file: CatalogImageWrap) => {
							const newFiles = props.files.filter((x) => file.name !== x.name);
							props.setFiles(newFiles);
						}}
					/>
				))}
			</TableBody>
		</Table>
	);
};

export default ImageTable;
