import * as React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { StoreContext } from 'stores/index';
import Title from 'components/title';
import NewsModel from 'models/newsModel';
import { useForm } from 'common/form/useForm';
import ActivityIndicator from 'components/activityIndicator';
import { observer } from 'mobx-react-lite';
import { FormGroup, Divider, Button, FormLabel, Theme, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TextInput from 'components/form/textInput';
import RichTextEditor, { EditorValue } from 'react-rte';
import NewsImages from './components/newsImages';

const useStyles = makeStyles((theme: Theme) => ({
	richTextEditor: {
		marginTop: theme.spacing(1)
	},
	divider: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1)
	},
	saveButton: {}
}));

export interface NewsDetailsProps extends RouteComponentProps {
	newsId: string;
}

const NewsDetails: React.FunctionComponent<NewsDetailsProps> = observer((props) => {
	const styles = useStyles();
	const newsId = props.newsId;
	const { newsStore } = React.useContext(StoreContext);

	const [editorState, setEditorState] = React.useState<EditorValue | undefined>(undefined);
	const [missingBodyError, setMissingBodyError] = React.useState(false);

	const creatingNews = newsId === 'create';

	let news: NewsModel | undefined = undefined;
	if (!creatingNews) {
		news = newsStore.get_news(newsId);
	}

	if (news && !editorState) {
		setEditorState(RichTextEditor.createValueFromString(news.text, 'html'));
	}

	const defaultNews = new NewsModel({
		newsId: '0',
		order: 0,
		images: [],
		title: 'Ny Nyhed',
		text: ''
	});

	const initial: any = creatingNews ? defaultNews : {};

	const { handleSubmit, handleInputChange, inputs } = useForm(async (obj) => {
		if (!obj.text || obj.text.length === 0) {
			setMissingBodyError(true);
			return false;
		}

		if (creatingNews) {
			delete obj.id;
			delete obj.order;
			delete obj.images;
			await newsStore.create_news(obj).then(() => {
				navigate('/news');
			});
		} else {
			await newsStore.update_news(newsId, obj);
		}

		return true;
	}, initial);

	const handleDelete = async () => {
		await newsStore.delete_news(newsId);
		navigate('/news', { replace: true });
	};

	if (!news && !creatingNews) {
		return <ActivityIndicator message="Indlæser nyhed..." />;
	}

	const initialInput: any = creatingNews ? defaultNews : news;

	const input = {
		...initialInput,
		...inputs
	};

	return (
		<React.Fragment>
			<Title>{creatingNews ? 'Opret Nyhed' : 'Ret Nyhed'}</Title>
			<Grid container spacing={3}>
				<Grid item xs={6}>
					<FormGroup row={false}>
						<TextInput name="title" label="Titel" object={input} onChange={handleInputChange} />
						<FormLabel style={{ marginTop: '1em' }}>Tekst</FormLabel>
						<div className={styles.richTextEditor}>
							<RichTextEditor
								value={editorState ?? RichTextEditor.createEmptyValue()}
								onChange={(event: any) => {
									setEditorState(event);
									handleInputChange({
										target: {
											name: 'text',
											value: event.toString('html')
										},
										persist: () => {}
									});
								}}
							/>

							{missingBodyError && (
								<Typography style={{ color: 'red' }} variant="caption">
									Du mangler en tekst
								</Typography>
							)}
						</div>
					</FormGroup>
					<Divider className={styles.divider} />

					<Button className={styles.saveButton} variant="outlined" color="primary" onClick={handleSubmit}>
						Gem Ændringer
					</Button>

					{!creatingNews && (
						<Button
							className={styles.saveButton}
							style={{ float: 'right' }}
							variant="outlined"
							color="secondary"
							onClick={handleDelete}
						>
							Slet
						</Button>
					)}
				</Grid>
				<Grid item xs={6}>
					{newsId !== 'create' && <NewsImages input={initialInput} newsId={newsId} />}
				</Grid>
			</Grid>
		</React.Fragment>
	);
});

export default NewsDetails;
