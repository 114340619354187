import { RouteComponentProps } from '@reach/router';
import Title from 'components/title';
import * as React from 'react';
import AuctionsTable from './components/auctionsTable';

const Auctions: React.FunctionComponent<RouteComponentProps> = () => {
	return (
		<React.Fragment>
			<Title>Auktioner</Title>
			<AuctionsTable />
		</React.Fragment>
	);
};

export default Auctions;
