import * as React from 'react';
import Title from 'components/title';
import { RouteComponentProps, navigate } from '@reach/router';
import { StoreContext } from 'stores/index';
import ActivityIndicator from 'components/activityIndicator';
import { useForm } from 'common/form/useForm';
import { LotModel } from 'models/lotModel';
import { FormGroup, Divider, Button, Grid, Typography, FormControl } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { observer } from 'mobx-react-lite';
import NumberInput from 'components/form/numberInput';
import TextAreaInput from 'components/form/textAreaInput';
import SwitchInput from 'components/form/switchInput';
import OrderedImagePresentationTable from 'components/orderedImagePresentationTable';
import RowImage from 'models/interfaces/rowImage';
import config from 'utilities/config';
import DateTimePicker from '../../components/form/dateTimePicker';
import TextInput from 'common/form/textInput';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		element: {
			padding: theme.spacing(2, 0)
		},
		divider: {
			margin: theme.spacing(2, 2, 2, 2)
		}
	})
);

export interface LotDetailsProps extends RouteComponentProps {
	catalogId: string;
	lotId: string;
}

const EditLot: React.FunctionComponent<LotDetailsProps> = observer(({ catalogId, lotId }) => {
	const styles = useStyles();
	const { catalogStore } = React.useContext(StoreContext);
	const catalog = catalogStore.catalog(catalogId);

	const isCreatingLot = lotId === 'create';

	let lotModel: LotModel | undefined = undefined;
	if (!isCreatingLot) {
		lotModel = catalogStore.lot(catalogId, lotId);
	}

	const defaultLot = new LotModel({
		catalogNumber: 1,
		minimumBid: 200
	});

	const initial: any = isCreatingLot ? defaultLot : {};

	const { handleSubmit, handleInputChange, inputs } = useForm(async (obj) => {
		if (isCreatingLot) {
			// TODO: Make it possible to add images during lot creation - might not be possible until we do a inventory system
			delete obj.images;
			delete obj.id;
			await catalogStore.create_lot(catalogId, obj).then(() => {
				navigate(`/catalogs/${catalogId}`);
			});
		} else {
			await catalogStore.update_lot(catalogId, lotId, obj);
		}

		return true;
	}, initial);

	if (!catalog || (!lotModel && !isCreatingLot)) {
		return <ActivityIndicator message={'Indlæser lot...'} />;
	}

	const initialInput: any = isCreatingLot ? defaultLot : lotModel;

	const lot: LotModel = {
		...initialInput,
		...inputs
	};

	let title = '';
	if (isCreatingLot) {
		title = 'Opret nyt lot';
	} else {
		title = `Rediger lot: ${lot.catalogNumber}`;
	}

	return (
		<React.Fragment>
			<Title>{title}</Title>
			<Grid container spacing={3}>
				<Grid item xs={4}>
					<FormGroup row={false}>
						<NumberInput
							name="catalogNumber"
							label="Katalog nummer"
							object={lot}
							onChange={handleInputChange}
						/>
						<TextInput fieldName="creator" label="Kunstner" object={lot} onChange={handleInputChange} />
						<TextAreaInput name="text" label="Beskrivelse" object={lot} onChange={handleInputChange} />
						<TextAreaInput name="lotNote" label="Note" object={lot} onChange={handleInputChange} />
						<React.Fragment>
							<SwitchInput
								name="copyDanRestricted"
								label="CopyDan"
								object={lot}
								onChange={handleInputChange}
							/>
							<Typography variant="caption">
								Er lottet omfattet af CopyDan? I så fald vil billeder blive skjult efter CopyDan
								forsinkelses tidspunktet defineret på selve auktionen. Dette vises også i kataloget ved
								et /CD efter titlen.
							</Typography>
						</React.Fragment>
						<React.Fragment>
							<SwitchInput
								style={{ marginTop: '1em' }}
								name="fullVAT"
								label="Fuld moms"
								object={lot}
								onChange={handleInputChange}
							/>
							<Typography variant="caption">
								Skal lottet sælges til fuld moms? Fuld moms lots vil blive opkrævet standard salær + 25%
								moms oveni. Dette vises på et katalog ved en * efter titlen.
							</Typography>
						</React.Fragment>

						<NumberInput
							style={{ marginTop: '1em' }}
							name="minimumBid"
							label="Minimumsbud"
							object={lot}
							onChange={handleInputChange}
						/>

						<NumberInput
							name="valuation"
							label="Vurdering"
							style={{ marginTop: '1em' }}
							object={lot}
							onChange={handleInputChange}
						/>

						{catalog.auction.onlineOnlyAuction && (
							<React.Fragment>
								<FormControl style={{ marginTop: '1em' }}>
									<DateTimePicker
										label="Bud deadline"
										value={lot.bidDeadline ? lot.bidDeadline : catalog.auction.biddingDeadline}
										name="bidDeadline"
										onChange={handleInputChange}
										disablePast={false}
									/>
								</FormControl>
								<Typography variant="caption">
									Tidspunktet for hvornår der ikke længere kan bydes på lottet. Dette er sat til
									auktionens afslutning per standard.
								</Typography>
							</React.Fragment>
						)}
					</FormGroup>

					<Divider className={styles.divider} />
					<Button variant="outlined" color="primary" onClick={handleSubmit}>
						Gem Ændringer
					</Button>
				</Grid>
				{!isCreatingLot && (
					<Grid item xs={8}>
						<Grid container>
							<Grid item xs={6}>
								<Typography variant="h6">Billeder</Typography>
							</Grid>
							<Grid item xs={6}>
								<Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
									<Button
										style={{ marginTop: '1em' }}
										variant="outlined"
										onClick={() => {
											navigate(`/catalogs/${catalogId}/lots/${lotId}/addImage`);
										}}
									>
										Tilføj billede
									</Button>
								</Grid>
							</Grid>
						</Grid>

						<OrderedImagePresentationTable
							isCatalog={true}
							baseUrl={config().catalog_image_path}
							images={lot.images}
							onReorder={(id, up) => {
								catalogStore.reorder_images(catalogId, lotId, id, up);
							}}
							onDelete={async (image: RowImage) => {
								await catalogStore.delete_image(catalogId, lotId, image.id);
							}}
						/>
					</Grid>
				)}
			</Grid>
		</React.Fragment>
	);
});

export default EditLot;
