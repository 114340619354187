import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

export interface SureDialogProps {
	open: boolean;
	body: string;
	acceptMessage: string;
	handleClose: (agree: boolean) => void;
}

const SureDialog: React.FunctionComponent<SureDialogProps> = (props) => {
	return (
		<Dialog
			open={props.open}
			onClose={props.handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{'Er du sikker?'}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{props.body}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						props.handleClose(false);
					}}
					color="secondary"
				>
					Nej tak
				</Button>
				<Button
					onClick={() => {
						props.handleClose(true);
					}}
					color="primary"
					autoFocus
				>
					{props.acceptMessage}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SureDialog;
