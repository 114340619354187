import * as React from 'react';
import { TableRow, TableCell, Button } from '@mui/material';
import { CatalogImageWrap } from '../models/CatalogImageWrap';

export interface ImportImageRowProps {
	file: CatalogImageWrap;
	onDelete: (file: CatalogImageWrap) => void;
}

const ImportImageRow: React.FunctionComponent<ImportImageRowProps> = ({ file, onDelete }) => {
	let defaults = {
		height: 100,
		width: 100,
		backgroundColor: 'clear'
	};

	let important = {
		backgroundImage: `url("${URL.createObjectURL(file.file)}")`,
		backgroundSize: 'contain',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat'
	};

	return (
		<TableRow key={file.name}>
			<TableCell>
				<div style={{ ...defaults, ...important }} />
			</TableCell>
			<TableCell>{file.name}</TableCell>
			<TableCell>{file.catalogNumber}</TableCell>
			<TableCell>{file.order}</TableCell>
			<TableCell align="right">
				<Button
					variant="outlined"
					onClick={() => {
						onDelete(file);
					}}
				>
					Slet
				</Button>
			</TableCell>
		</TableRow>
	);
};

export default ImportImageRow;
