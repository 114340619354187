import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import { globalHistory } from '@reach/router';
import Toast from 'components/toast';
import ContentRouting from 'contentRouting';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import 'moment/locale/da';
import React from 'react';
import ReactGA from 'react-ga';
import './App.css';
import TopBar from './components/topbar';

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

ReactGA.initialize('UA-159231959-2');
globalHistory.listen((listener) => {
	const path = listener.location.pathname;
	ReactGA.pageview(path);
});

moment.locale('da');

const theme = createTheme();

const App: React.FunctionComponent = observer((props) => {
	return (
		<ThemeProvider theme={theme}>
			<StyledEngineProvider injectFirst>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<React.Fragment>
						<CssBaseline />
						<TopBar />
						<Toast />

						<ContentRouting />
					</React.Fragment>
				</LocalizationProvider>
			</StyledEngineProvider>
		</ThemeProvider>
	);
});

export default App;
