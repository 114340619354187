import InboxIcon from '@mui/icons-material/Inbox';
import { Grid, List, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material';
import { RouteComponentProps } from '@reach/router';
import { AxiosError } from 'axios';
import Network, { ServiceEndpoint } from 'common/network';
import Title from 'components/title';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { EditorValue } from 'react-rte';
import { StoreContext } from 'stores';
import { Severity } from 'stores/ToastStore';
import MessageInputArea from './components/messageInputArea';
import SureDialog from './components/sureDialog';
import TemplateSelector from './components/templateSelector';

export interface MessagesProps extends RouteComponentProps {}

const Messages: React.FunctionComponent<MessagesProps> = observer(() => {
	const [showDialog, setShowDialog] = React.useState<boolean>(false);
	const [subject, setSubject] = React.useState<string>('');
	const [body, setBody] = React.useState<EditorValue>(EditorValue.createEmpty());
	const { toastStore } = React.useContext(StoreContext);

	return (
		<React.Fragment>
			<Title>Skriv Besked</Title>
			<Grid container spacing={3}>
				<Grid item xs={2}>
					<TemplateSelector
						setSubject={setSubject}
						setBody={(x) => setBody(EditorValue.createFromString(x, 'html'))}
					/>
					<Paper style={{ marginTop: '1em' }}>
						<List>
							<ListItem
								button
								onClick={async () => {
									setShowDialog(true);
								}}
							>
								<ListItemIcon>
									<InboxIcon />
								</ListItemIcon>
								<ListItemText primary="Send" />
							</ListItem>
						</List>
					</Paper>
				</Grid>
				<Grid item xs={10}>
					<MessageInputArea subject={subject} body={body} setSubject={setSubject} setBody={setBody} />
				</Grid>
			</Grid>
			<SureDialog
				open={showDialog}
				body="Er du sikker på at du vil sende denne besked til alle brugerne?"
				acceptMessage="Send"
				handleClose={async (send: boolean) => {
					setShowDialog(false);
					if (!send) {
						return;
					}

					try {
						await Network.post(ServiceEndpoint.Node, 'messages/send', {
							subject,
							body: body.toString('html')
						});

						toastStore.showMessage('Besked sendt', Severity.Information);
					} catch (ex: any) {
						if (ex.isAxiosError) {
							const axiosError = ex as AxiosError;
							toastStore.showMessage(axiosError.response?.data, Severity.Error);
						} else {
							console.error(ex);
							toastStore.showMessage(`Beskeden kunne ikke sendes: ${ex}`, Severity.Error);
						}
					}
				}}
			/>
		</React.Fragment>
	);
});

export default Messages;
