import React from 'react';
// import Title from 'components/title';
import { RouteComponentProps } from '@reach/router';
// import useRequest from '../../utilities/useRequest';
// import ActivityIndicator from 'components/activityIndicator';
// import { TextField, Button } from '@mui/material';

export interface SearchPageProps extends RouteComponentProps {}

const SearchPage: React.FunctionComponent<SearchPageProps> = () => {
	// const [searchString, setSearchString] = React.useState('');
	// const [searchFor, setSearchFor] = React.useState<string | undefined>(undefined);
	// const { data, loading, errorMessage } = useRequest(`search/lots?term=${searchFor}`)

	return (
		<React.Fragment>
			{/* <Title>Søg</Title>
            <TextField label="Søg efter:" type="text" onChange={(e) => setSearchString(e.currentTarget.value)}></TextField>
            <Button variant="outlined" onClick={() => {console.log("søg")}}>Søg</Button>
            {loading && <ActivityIndicator>Søger...</ActivityIndicator>} */}
		</React.Fragment>
	);
};

export default SearchPage;
