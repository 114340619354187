import AuctionViewingModel from './auctionViewingModel';
import { IAuctionDto } from '../common/responses/IAuctionDto';

class AuctionModel {
	id: string;
	title: string;
	catalogId?: string;
	date?: Date;
	biddingDeadline?: Date;
	canBid: boolean;
	copyDanDelay?: number;
	published?: boolean;
	completed: boolean;
	viewings: AuctionViewingModel[];
	onlineOnlyAuction: boolean;
	archived: boolean;
	note: string;

	constructor(obj: IAuctionDto) {
		this.id = obj.id;
		this.title = obj.title;
		this.note = obj.note;
		this.catalogId = obj.catalogId;
		if (obj.date) {
			this.date = new Date(obj.date);
		} else {
			this.date = undefined;
		}

		if (obj.biddingDeadline) {
			this.biddingDeadline = new Date(obj.biddingDeadline);
		} else {
			this.biddingDeadline = undefined;
		}

		this.canBid = obj.canBid;
		this.copyDanDelay = obj.copyDanDelay;
		this.published = obj.published;
		this.completed = obj.completed;
		this.viewings = obj.viewings.map((x: any) => {
			return new AuctionViewingModel(new Date(x.from), new Date(x.to));
		});
		this.onlineOnlyAuction = obj.onlineOnlyAuction;
		this.archived = obj.archived;
	}
}

export default AuctionModel;
