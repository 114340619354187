import { createContext } from 'react';
import { AuctionsStore } from './AuctionsStore';
import { CatalogStore } from './CatalogStore';
import { MailTemplatesStore } from './MailTemplates';
import { NewsStore } from './NewsStore';
import { OnlineUsersStore } from './OnlineUsersStore';
import { PhoneUsersStore } from './PhoneUsersStore';
import { ToastStore } from './ToastStore';
import { UserStore } from './UserStore';

class Store {
	toastStore = new ToastStore(this);
	userStore = new UserStore(this);
	auctionsStore = new AuctionsStore(this);
	catalogStore = new CatalogStore(this);
	newsStore = new NewsStore(this);
	phoneUsersStore = new PhoneUsersStore(this);
	onlineUsersStore = new OnlineUsersStore(this);
	mailTemplatesStore = new MailTemplatesStore(this);
}

export type IStore = Store;

export const StoreContext = createContext(new Store());
