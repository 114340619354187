export class CatalogSectionModel {
	id: string;
	fromLot: number;
	title: string;

	constructor(obj: any) {
		this.id = String(obj.id);
		this.fromLot = obj.fromLot;
		this.title = obj.title;
	}
}
