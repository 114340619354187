import Network, { ServiceEndpoint } from 'common/network';
import { action, makeObservable, observable } from 'mobx';
import OrderedObject from 'models/interfaces/orderedObject';
import NewsImageModel from 'models/newsImageModel';
import NewsModel from 'models/newsModel';
import { IStore } from 'stores';
import ReorderList from 'utilities/reorderList';
import { INewsDto } from '../common/responses/INewsDto';
import { Severity, ToastStore } from './ToastStore';

export class NewsStore {
	toastStore: ToastStore;
	constructor(private rootStore: IStore) {
		makeObservable(this);
		this.toastStore = rootStore.toastStore;
	}

	@observable news: NewsModel[] | undefined;

	get_all_news() {
		if (this.news) {
			return this.news;
		}

		this.fetchNews();
	}

	get_news(newsId: string) {
		if (this.news) {
			const news = this.news.find((x) => x.id === newsId);
			return news;
		}

		this.fetchNews();
	}

	async create_news(newsModel: NewsModel) {
		try {
			await Network.post(ServiceEndpoint.Node, `news`, newsModel);
			this.toastStore.showMessage('Nyhed oprettet', Severity.Information);
		} catch (ex: any) {
			this.errorHandler(ex);
		}

		this.fetchNews();
	}

	async update_news(newsId: string, newsModel: any) {
		try {
			await Network.put(ServiceEndpoint.Node, `news/${newsId}`, newsModel);
			this.toastStore.showMessage('Nyhed opdateret', Severity.Information);
		} catch (ex: any) {
			this.errorHandler(ex);
		}

		this.fetchNews();
	}

	async all_news_update_order(newsId: string, moveUp: boolean) {
		if (!this.news) {
			console.debug('Trying to sort null list');
			return;
		}

		ReorderList(
			this.toastStore,
			this.news,
			newsId,
			moveUp,
			async (order) => {
				await Network.put(ServiceEndpoint.Node, 'news/updateOrder', order);
			},
			(items: OrderedObject[]) => {
				this.news = items as NewsModel[];
			}
		);
	}

	async news_images_update_order(newsId: string, imageId: string, moveUp: boolean) {
		const news = this.news;
		if (!news) {
			console.error('Trying to sort null list');
			return;
		}

		const currentNews: NewsModel | undefined = news.find((x) => x.id === newsId);
		if (!currentNews) {
			console.error('Failed to find the relevant news article');
			return;
		}

		ReorderList(
			this.toastStore,
			currentNews.images,
			imageId,
			moveUp,
			async (ordering: string[]) => {
				await Network.put(ServiceEndpoint.Node, `news/${newsId}/images/updateOrder`, ordering);
			},
			(items: OrderedObject[]) => {
				currentNews.images = items.sort((x, y) => x.order - y.order) as NewsImageModel[];
				this.news = [...news.filter((x) => x.id !== newsId), currentNews];
			}
		);
	}

	async add_images(newsId: string, files: File[], callback: (completed: number, total: number) => void) {
		const promises = files.map((file) => this.upload_image(newsId, file));
		try {
			var counter = 0;
			for (const promise of promises) {
				await promise;
				counter += 1;
				callback(counter, promises.length);
			}
		} catch (ex: any) {
			this.errorHandler(ex);
		}

		this.fetchNews();
	}

	async upload_image(newsId: string, file: File): Promise<void> {
		type Response = {
			uploadUrl: string;
			entityName: string;
		};

		// safari likes to cache even though we ask it not to.
		const upload = await Network.get<Response>(
			ServiceEndpoint.Node,
			`import/upload_url?safaricanbiteme=${Math.random()}`
		);

		await Network.put(ServiceEndpoint.Other, upload.uploadUrl, file, false, {
			headers: {
				'Content-Type': `image/jpeg`
			}
		});

		await Network.post(ServiceEndpoint.Node, `news/${newsId}/import`, { entityName: upload.entityName });
	}

	async delete_image(newsId: string, imageId: string) {
		try {
			await Network.delete(ServiceEndpoint.Node, `news/${newsId}/images/${imageId}`);
			await this.fetchNews();
			this.toastStore.showMessage('Billede slettet', Severity.Information);
		} catch (ex: any) {
			this.errorHandler(ex);
		}
	}

	@action async fetchNews() {
		try {
			const result = await Network.get<INewsDto[]>(ServiceEndpoint.Node, 'news');
			const newsModels: NewsModel[] = result
				.map((x) => new NewsModel(x))
				.sort((x: NewsModel, y: NewsModel) => x.order - y.order)
				.map((x: NewsModel, index: number) => {
					x.order = index;
					return x;
				});

			this.news = newsModels;
		} catch (ex: any) {
			this.errorHandler(ex);
		}
	}

	async delete_news(newsId: string) {
		try {
			await Network.delete(ServiceEndpoint.Node, `news/${newsId}`);
			await this.fetchNews();
			this.toastStore.showMessage('Nyhed slettet', Severity.Information);
		} catch (ex: any) {
			this.errorHandler(ex);
		}
	}

	errorHandler = (ex: any) => {
		console.error(ex);
		if (ex.response) {
			this.toastStore.showMessage(ex.response.data, Severity.Error);
		} else {
			this.toastStore.showMessage(ex.message, Severity.Error);
		}
	};
}
