import { Button, Container, Typography } from '@mui/material';
import { navigate, RouteComponentProps } from '@reach/router';
import ActivityIndicator from 'components/activityIndicator';
import Title from 'components/title';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { StoreContext } from 'stores';
import config from 'utilities/config';
import { CatalogModel } from '../../models/catalogModel';
import ImageTable from './components/imageTable';
import { CatalogImageWrap } from './models/CatalogImageWrap';

export interface ImportImagesProps extends RouteComponentProps {
	catalogId: string;
}

const ImportImages: React.FunctionComponent<ImportImagesProps> = observer((props) => {
	const { catalogStore } = React.useContext(StoreContext);
	const [files, setFiles] = React.useState(new Array<CatalogImageWrap>());
	const [error, setError] = React.useState<string | undefined>(undefined);
	const [uploading, setUploading] = React.useState(false);
	const [progress, setProgress] = React.useState(0);
	const [catalogModel, setCatalogModel] = React.useState<CatalogModel | undefined>(undefined);

	const onDrop = (acceptedFiles: File[]) => {
		var wraps = new Array<CatalogImageWrap>();
		for (var file of acceptedFiles) {
			try {
				wraps.push(new CatalogImageWrap(file, catalogModel!));
			} catch (exception: any) {
				setError(exception.message);
				return;
			}
		}

		setFiles([...files, ...wraps]);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	const catalog = catalogStore.catalog(props.catalogId);
	if (!catalog) {
		return <ActivityIndicator message={`Indlæser katalog`} />;
	}

	if (!catalogModel) {
		setCatalogModel(catalog);
	}

	if (uploading) {
		return <ActivityIndicator message={`Tilføjer billeder: ${progress}%`} />;
	}

	const showImagesBeforeUpload: boolean = config().features.showImagesBeforeUpload;

	return (
		<Container maxWidth="md">
			<Title>Importer billeder</Title>
			<div {...getRootProps()}>
				<input {...getInputProps()} accept="image/*" />
				{isDragActive ? (
					<Button variant="outlined">Placer filerne her ...</Button>
				) : (
					<Button variant="outlined">
						Træk billederne til lot'et her - ellers tryk for at vælge dem i en dialog
					</Button>
				)}
			</div>

			{showImagesBeforeUpload && files.length > 0 && <ImageTable files={files} setFiles={setFiles} />}

			{files.length > 0 && (
				<Button
					style={{ marginTop: '1em' }}
					variant="outlined"
					color="primary"
					onClick={async () => {
						setUploading(true);
						await catalogStore.batch_add_images(props.catalogId, files, (completed, total) => {
							setProgress(Math.round((completed / total) * 100));
						});
						navigate(`/catalogs/${props.catalogId}`, { replace: true });
						setUploading(false);
					}}
				>
					Upload {files.length} billeder
				</Button>
			)}

			{files.length > 0 && (
				<Button
					style={{ marginLeft: '1em', marginTop: '1em' }}
					variant="outlined"
					color="secondary"
					onClick={async () => {
						setFiles(new Array<CatalogImageWrap>());
					}}
				>
					Ryd Billeder
				</Button>
			)}

			{error && (
				<Typography style={{ marginTop: '1em' }} variant="body1" color="error">
					{error}
				</Typography>
			)}
		</Container>
	);
});

export default ImportImages;
