import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import { Link as RouterLink, navigate } from '@reach/router';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { StoreContext } from 'stores';
import config from 'utilities/config';

export interface TopBarProps {}

const useStyles = makeStyles((theme) => ({
	appBar: {
		borderBottom: `1px solid ${theme.palette.divider}`
	},
	toolbar: {
		flexWrap: 'wrap'
	},
	toolbarTitle: {
		flexGrow: 1
	},
	link: {
		margin: theme.spacing(1, 1.5)
	}
}));

const TopBar: React.FunctionComponent<TopBarProps> = observer(() => {
	const classes = useStyles();
	const { userStore } = React.useContext(StoreContext);

	return (
		<AppBar position="static" color="default" elevation={0} className={classes.appBar}>
			<Toolbar className={classes.toolbar}>
				<Link
					variant="h5"
					color="inherit"
					noWrap
					className={classes.toolbarTitle}
					to="/"
					component={RouterLink}
				>
					Holstebro Auktioner Admin
				</Link>
				<nav>
					{userStore.isAuthenticated && (
						<React.Fragment>
							<Link
								variant="button"
								color="textPrimary"
								href="#"
								className={classes.link}
								component={RouterLink}
								to="/auctions"
							>
								Auktioner
							</Link>
							<Link
								variant="button"
								color="textPrimary"
								href="#"
								className={classes.link}
								component={RouterLink}
								to="/news"
							>
								Nyheder
							</Link>
							<Link
								variant="button"
								color="textPrimary"
								href="#"
								className={classes.link}
								component={RouterLink}
								to="/users"
							>
								Brugere
							</Link>
							{config().features.enablePhoneBidders && (
								<Link
									variant="button"
									color="textPrimary"
									href="#"
									className={classes.link}
									component={RouterLink}
									to="/phone_users"
								>
									Telefon Bydere
								</Link>
							)}
							<Link
								variant="button"
								color="textPrimary"
								href="#"
								className={classes.link}
								component={RouterLink}
								to="/messages"
							>
								Beskeder
							</Link>
							<Button
								href="#"
								color="primary"
								variant="outlined"
								className={classes.link}
								onClick={(element) => {
									if (userStore.isAuthenticated) {
										userStore.logout();
									} else {
										navigate('/logon');
									}
								}}
							>
								{userStore.isAuthenticated
									? `Log ud (${
											userStore.profile
												? `${userStore.profile!.onlineUser?.firstName} ${
														userStore.profile!.onlineUser?.lastName
												  }`
												: 'Ukendt'
									  })`
									: 'Log på'}
							</Button>
						</React.Fragment>
					)}
					{!userStore.isAuthenticated && (
						<Link
							variant="button"
							color="textPrimary"
							href="#"
							className={classes.link}
							component={RouterLink}
							to="/logon"
						>
							Login
						</Link>
					)}
				</nav>
			</Toolbar>
		</AppBar>
	);
});

export default TopBar;
