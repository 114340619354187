import * as React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { navigate } from '@reach/router';
import OrderedImagePresentationTable from 'components/orderedImagePresentationTable';
import RowImage from 'models/interfaces/rowImage';
import config from 'utilities/config';
import NewsModel from 'models/newsModel';
import { StoreContext } from 'stores';

export interface NewsImagesProps {
	newsId: string;
	input: NewsModel;
}

const NewsImages: React.FunctionComponent<NewsImagesProps> = ({ newsId, input }) => {
	const { newsStore } = React.useContext(StoreContext);
	return (
		<React.Fragment>
			<Grid container>
				<Grid item xs={6}>
					<Typography variant="h6">Billeder</Typography>
				</Grid>
				<Grid item xs={6}>
					<Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
						<Button
							style={{ marginTop: '1em' }}
							variant="outlined"
							onClick={() => {
								navigate(`/news/${newsId}/addImage`);
							}}
						>
							Tilføj billede
						</Button>
					</Grid>
				</Grid>
			</Grid>

			<OrderedImagePresentationTable
				isCatalog={false}
				baseUrl={config().news_image_path}
				images={input.images}
				onReorder={(id, up) => {
					newsStore.news_images_update_order(newsId, id, up);
				}}
				onDelete={(image: RowImage) => {
					newsStore.delete_image(newsId, image.id);
				}}
			/>
		</React.Fragment>
	);
};

export default NewsImages;
