import AuctionModel from 'models/auctionModel';

export default class AuctionsItemViewModel {
	id: string;
	title: string;
	date: string;
	hasCatalog: boolean;
	published: boolean;

	constructor(auctionModel: AuctionModel) {
		this.id = auctionModel.id;
		this.title = auctionModel.title;
		if (auctionModel.date) {
			this.date = auctionModel.date.toLocaleString('da-DK', { timeZone: 'Europe/Copenhagen' });
		} else {
			this.date = '';
		}
		this.hasCatalog = !!auctionModel.catalogId;
		this.published = auctionModel.published ?? false;
	}
}
