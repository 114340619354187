import { Button, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import Network, { ServiceEndpoint } from 'common/network';
import ActivityIndicator from 'components/activityIndicator';
import iconv from 'iconv-lite';
import { observer } from 'mobx-react-lite';
import ImportPrices from 'pages/importPrices';
import React from 'react';
import { StoreContext } from 'stores';
import { Severity } from 'stores/ToastStore';

export interface ExportDataProps {
	auctionId: string;
}

const ExportData: React.FunctionComponent<ExportDataProps> = observer((props) => {
	const { auctionsStore, toastStore } = React.useContext(StoreContext);
	const auction = auctionsStore.get_auction(props.auctionId);
	if (!auction) {
		return <ActivityIndicator>Indlæser auktion</ActivityIndicator>;
	}

	const handleAusyExport = async () => {
		const ausy: string = await Network.get(ServiceEndpoint.Node, `export/${props.auctionId}/sales`);

		const result = iconv.encode(ausy, 'iso-8859-1');

		const data = new Blob([result], { type: 'text/csv;charset=iso-8865-1' });
		const url = window.URL.createObjectURL(data);

		const link = document.createElement('a');
		link.href = url;
		link.download = 'alle_bud_fra_auktion.csv';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const handleConsignmentBidPagesExport = async () => {
		type ExportResponse = {
			downloadUrl: string;
		};

		const requestResult = await Network.get<ExportResponse>(
			ServiceEndpoint.Node,
			`export/${props.auctionId}/consignmentBids`
		);
		const url = requestResult.downloadUrl;

		const link = document.createElement('a');
		link.href = url;
		link.download = 'kommissionsbud.pdf';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const handleAllBidsExport = async () => {
		type ExportBidsResponse = {
			downloadUrl: string;
		};

		try {
			const requestResult = await Network.get<ExportBidsResponse>(
				ServiceEndpoint.Node,
				`export/${props.auctionId}/bids`
			);
			const url = requestResult.downloadUrl;

			const link = document.createElement('a');
			link.href = url;
			link.download = 'alle_bud.pdf';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (err: any) {
			if (err.isAxiosError) {
				const axiosError = err as AxiosError;
				const errorMessage = axiosError.response?.data as String;
				if (errorMessage) {
					toastStore.showMessage(errorMessage, Severity.Error);
				}
			}
		}
	};

	return (
		<React.Fragment>
			<Typography variant="body1" gutterBottom>
				Højeste bud per bruger - CSV:
			</Typography>
			<Button variant="outlined" onClick={() => handleAusyExport()}>
				Download
			</Button>

			<Typography variant="body1" style={{ marginTop: '1em' }} gutterBottom>
				Alle bud - PDF:
			</Typography>
			<Button variant="outlined" onClick={() => handleAllBidsExport()}>
				Download
			</Button>

			{!auction.onlineOnlyAuction && (
				<React.Fragment>
					<Typography variant="body1" style={{ marginTop: '1em' }} gutterBottom>
						Kommissionsbudsedler - PDF:
					</Typography>
					<Button variant="outlined" onClick={() => handleConsignmentBidPagesExport()}>
						Download
					</Button>
				</React.Fragment>
			)}

			{/* {!auction.archived && ( */}
			<React.Fragment>
				<Typography variant="body1" style={{ marginTop: '1em' }} gutterBottom>
					Når alt er faktureret kan prislisten importeres:
				</Typography>
				<ImportPrices auctionId={props.auctionId} completed={() => {}} deactivated={auction.archived} />
			</React.Fragment>
			{/* )} */}
		</React.Fragment>
	);
});

export default ExportData;
