import { Button, TableCell, TableRow } from '@mui/material';
import { navigate } from '@reach/router';
import { IBidderDto } from 'common/responses/IBidderDto';
import * as React from 'react';

export interface PagedUserRowProps {
	user: IBidderDto;
}

const PagedUserRow: React.FunctionComponent<PagedUserRowProps> = ({ user }) => {
	const onlineUser = user.onlineUser;
	if (!onlineUser) {
		return (
			<TableRow
				style={{ cursor: 'pointer' }}
				onClick={() => {
					navigate(`/users/${user.id}`);
				}}
			>
				<TableCell align="left">Bruger har ingen online profil</TableCell>
			</TableRow>
		);
	}

	return (
		<TableRow
			style={{ cursor: 'pointer' }}
			onClick={() => {
				navigate(`/users/${user.id}`);
			}}
		>
			<TableCell align="left">{onlineUser.firstName}</TableCell>
			<TableCell align="left">{onlineUser.lastName}</TableCell>
			<TableCell align="left">{onlineUser.companyName}</TableCell>
			<TableCell align="left">{onlineUser.streetName}</TableCell>
			<TableCell align="left">{onlineUser.postalCode}</TableCell>
			<TableCell align="left">{onlineUser.country}</TableCell>
			<TableCell align="left">{onlineUser.blocked ? 'Blokeret' : ''}</TableCell>
			<TableCell align="right">
				<Button
					variant="outlined"
					onClick={() => {
						navigate(`/users/${user.id}`);
					}}
				>
					Detaljer
				</Button>
			</TableCell>
		</TableRow>
	);
};

export default PagedUserRow;
