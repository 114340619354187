import * as React from 'react';
import { Grid, CircularProgress, Typography } from '@mui/material';

export interface IActivityIndicatorProps {
	message?: string;
}

const ActivityIndicator: React.FunctionComponent<IActivityIndicatorProps> = ({ message }) => {
	return (
		<React.Fragment>
			<Grid container justifyContent="center">
				<Grid item>
					<CircularProgress />
				</Grid>
			</Grid>
			{message && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<Typography variant="body1">{message}</Typography>
				</div>
			)}
		</React.Fragment>
	);
};

export default ActivityIndicator;
