import config from 'utilities/config';
import IImageDto from 'common/responses/IImageDto';

export default class ImageResource {
	source: string;
	width: number;
	height: number;

	static make(image: IImageDto) {
		return new ImageResource(image.path, image.imageWidth, image.imageHeight);
	}

	constructor(source: string, width: number, height: number) {
		this.source = source;
		this.width = width;
		this.height = height;
	}

	catalogImageUrl(): string {
		return this.source;
	}

	newsImageUrl(): string {
		return `${config().news_image_path}${this.source}`;
	}
}
