import { Button, Container, FormControl, FormGroup, Input, InputLabel, Typography } from '@mui/material';
import { navigate, RouteComponentProps, useParams } from '@reach/router';
import Network, { ServiceEndpoint } from 'common/network';
import { IBidderDto } from 'common/responses/IBidderDto';
import { IOfflineBidderDto } from 'common/responses/IOfflineBidderDto';
import { IOnlineUserDto } from 'common/responses/IOnlineUserDto';
import ActivityIndicator from 'components/activityIndicator';
import Title from 'components/title';
import SureDialog from 'pages/messages/components/sureDialog';
import * as React from 'react';
import { StoreContext } from 'stores';
import { Severity } from 'stores/ToastStore';

function nameOfBidder(bidder: IBidderDto): string {
	if (bidder.onlineUser) {
		if (bidder.onlineUser.companyName) {
			return bidder.onlineUser.companyName;
		}

		if (bidder.onlineUser.firstName && bidder.onlineUser.lastName) {
			return `${bidder.onlineUser.firstName} ${bidder.onlineUser.lastName}`;
		} else if (bidder.onlineUser.firstName) {
			return bidder.onlineUser.firstName;
		} else {
			return 'Ukendt';
		}
	} else {
		return 'Slettet bruger';
	}
}

interface IBidDto {
	id: string;
	catalogId: string;
	lotId: string;
	bidderId: string;
	bid: number;
	time: string;
	notifyOnOverbid: boolean;
}

export interface EditBidPageProps {
	auctionId: string;
	catalogId: string;
	lotId: string;
	bidId: string;
}

class EmptyBidder implements IBidderDto {
	id: string = '';
	onlineUser: IOnlineUserDto | undefined = undefined;
	offlineUser: IOfflineBidderDto | undefined = undefined;
}

const EditBidPage: React.FunctionComponent<RouteComponentProps> = () => {
	const params = useParams<EditBidPageProps>();
	const { toastStore } = React.useContext(StoreContext);
	const [bid, setBid] = React.useState<IBidDto | undefined>(undefined);
	const [bidder, setBidder] = React.useState<IBidderDto | undefined>(undefined);
	const [updatedBid, updateBid] = React.useState<number | undefined>(undefined);

	const [showDialog, setShowDialog] = React.useState(false);

	React.useEffect(() => {
		Network.get<IBidDto>(ServiceEndpoint.Node, `consignmentbids/${params.bidId}`)
			.then((bid) => {
				setBid(bid);
				return Network.get<IBidderDto>(ServiceEndpoint.User, `bidder/${bid.bidderId}`);
			})
			.then((bidder) => {
				setBidder(bidder);
			})
			.catch(() => {
				setBidder(new EmptyBidder());
			});
	}, [params.bidId]);

	if (!bid || !bidder) {
		return <ActivityIndicator />;
	}

	const handleDelete = async () => {
		await Network.delete(ServiceEndpoint.Node, `/consignmentbids/${params.bidId}`);
		navigate(`/auctions/${params.auctionId}/online_bids/${params.catalogId}/${params.lotId}`, { replace: true });
	};

	const handleSubmit = async () => {
		try {
			await Network.put(ServiceEndpoint.Node, `/consignmentbids/${params.bidId}`, { bid: updatedBid });
			const bid = await Network.get<IBidDto>(ServiceEndpoint.Node, `consignmentbids/${params.bidId}`);
			toastStore.showMessage('Bud opdateret', Severity.Information);
			setBid(bid);
			updateBid(undefined);
			window.history.back();
		} catch (error: any) {
			console.log('error :>> ', error);
			toastStore.showMessage('Failed to update bid in database', Severity.Error);
		}
	};

	return (
		<Container maxWidth="sm">
			<Title>{'Rediger Bud'}</Title>
			<Typography variant="h6">{nameOfBidder(bidder)}</Typography>
			<FormGroup row={false}>
				<FormControl variant="standard">
					<InputLabel htmlFor="component-simple">Bud</InputLabel>
					<Input
						id="component-simple"
						name="title"
						value={updatedBid || bid.bid}
						onChange={(event) => {
							event.persist();
							const newBid = parseInt(event.target.value);
							if (!isNaN(newBid)) {
								updateBid(newBid);
							}
						}}
					/>
				</FormControl>
			</FormGroup>

			<div style={{ paddingTop: '2em' }}>
				{updatedBid && (
					<Button variant="outlined" color="primary" onClick={handleSubmit}>
						Gem Ændringer
					</Button>
				)}

				<Button
					style={{ float: 'right' }}
					variant="outlined"
					color="secondary"
					onClick={() => setShowDialog(true)}
				>
					Slet Bud
				</Button>
			</div>

			<SureDialog
				open={showDialog}
				body="Vil du slette buddet?"
				acceptMessage="Slet"
				handleClose={(perform) => {
					setShowDialog(false);
					if (!perform) {
						return;
					}

					handleDelete();
				}}
			/>
		</Container>
	);
};

export default EditBidPage;
