import React from 'react';
import Title from 'components/title';
import { RouteComponentProps } from '@reach/router';
import { Typography } from '@mui/material';
import FinishAuctionView from './views/finishAuctionView';
import { StoreContext } from 'stores';
import ActivityIndicator from 'components/activityIndicator';
import { observer } from 'mobx-react-lite';
import ExportData from './views/exportData';

export interface CompleteAuctionPageProps extends RouteComponentProps {
	auctionId: string;
}

export enum CompleteAuctionState {
	// Markere auktionen som 'completed' hvilket stopper al budgivning på lots
	completeAuction,
	// godkendelse af bud, når den er færdig vil der være oprettet salgsobjekter, sendt mails til kunder, samt arkiveret auktionen.
	validateBids,
	// her kan data exportes
	export
}

const CompleteAuctionPage: React.FunctionComponent<CompleteAuctionPageProps> = observer((props) => {
	const [finishState, setFinishState] = React.useState<CompleteAuctionState>(CompleteAuctionState.completeAuction);
	const { auctionsStore } = React.useContext(StoreContext);
	const auction = auctionsStore.get_auction(props.auctionId);
	if (!auction) {
		return <ActivityIndicator message="Indlæser auktion" />;
	}

	return (
		<React.Fragment>
			<Title>
				Afslut auktionen {auction.title} fra {auction.date!.toLocaleDateString()}
			</Title>
			{auction.archived && (
				<Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '1em' }} gutterBottom>
					Bemærk venligst at denne auktion er arkiveret allerede, og at nogle funktioner derfor er
					deaktiverede.
				</Typography>
			)}
			{finishState === CompleteAuctionState.completeAuction && (
				<FinishAuctionView
					auctionId={props.auctionId}
					completed={() => setFinishState(CompleteAuctionState.export)}
				/>
			)}
			{/* {finishState === CompleteAuctionState.validateBids && <ValidateBids auctionId={props.auctionId} completed={() => setFinishState(CompleteAuctionState.export)} />} */}
			{finishState === CompleteAuctionState.export && <ExportData auctionId={props.auctionId} />}
		</React.Fragment>
	);
});

export default CompleteAuctionPage;
