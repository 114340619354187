import * as React from 'react';
import { TableRow, TableCell, Button } from '@mui/material';

export interface AddLotImageRowProps {
	file: File;
	onDelete: (file: File) => void;
}

const AddLotImageRow: React.FunctionComponent<AddLotImageRowProps> = ({ file, onDelete }) => {
	let defaults = {
		height: 100,
		width: 100,
		backgroundColor: 'clear'
	};

	let important = {
		backgroundImage: `url("${URL.createObjectURL(file)}")`,
		backgroundSize: 'contain',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat'
	};

	return (
		<TableRow key={file.name}>
			<TableCell>
				<div style={{ ...defaults, ...important }} />
			</TableCell>
			<TableCell>{file.name}</TableCell>
			<TableCell align="right">
				<Button
					onClick={() => {
						onDelete(file);
					}}
				>
					Slet
				</Button>
			</TableCell>
		</TableRow>
	);
};

export default AddLotImageRow;
