import { Button } from '@mui/material';
import { RouteComponentProps } from '@reach/router';
import { Buffer } from 'buffer';
import ActivityIndicator from 'components/activityIndicator';
import iconv from 'iconv-lite';
import Papa from 'papaparse';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { StoreContext } from 'stores';
import { CreateSalesDto } from '../../common/responses/CreateSalesDto';
import { ICreateSalesDto } from '../../common/responses/ICreateSalesDto';
import ImportedPrice from './models/importedPrice';

export interface ImportPricesProps extends RouteComponentProps {
	auctionId: string;
	completed: () => void;
	deactivated: boolean;
}

const ImportPrices: React.FunctionComponent<ImportPricesProps> = (props) => {
	const { catalogStore, auctionsStore } = React.useContext(StoreContext);
	const [sales, setSales] = React.useState<ICreateSalesDto | undefined>(undefined);
	const [uploading, setUploading] = React.useState(false);

	const convertFile = (file: string) => {
		const csv = Papa.parse(file);
		const data = csv.data as string[][];
		data.shift();

		const imported = data.map((x) => new ImportedPrice(x));
		const sales = new CreateSalesDto(imported, props.auctionId);
		setSales(sales);
	};

	const importFile = (file: File) => {
		const fileReader = new FileReader();
		fileReader.onloadend = (event: any) => {
			const result = event.target.result;
			const buffer = Buffer.from(result, 'binary');
			const text = iconv.decode(buffer, 'CP865');
			convertFile(text);
		};
		fileReader.readAsArrayBuffer(file);
	};

	const onDrop = (acceptedFiles: File[]) => {
		importFile(acceptedFiles[0]);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	if (uploading) {
		return <ActivityIndicator message={`Uploader prisliste: ${catalogStore.batchUploadProgress} %`} />;
	}

	return (
		<React.Fragment>
			<div {...getRootProps()}>
				<input {...getInputProps()} accept="text/*" disabled={props.deactivated} />
				{isDragActive ? (
					<Button variant="outlined" disabled={props.deactivated}>
						Placer filen her ...
					</Button>
				) : (
					<Button variant="outlined" disabled={props.deactivated}>
						Importer Prisliste
					</Button>
				)}
			</div>
			{!!sales && (
				<Button
					style={{ marginTop: '1em' }}
					variant="outlined"
					color="primary"
					onClick={async () => {
						setUploading(true);
						await catalogStore.batch_upload_prices(sales);
						setUploading(false);
						auctionsStore.clear();
						props.completed();
					}}
				>
					Opret salg & Arkiver Auktion
				</Button>
			)}
		</React.Fragment>
	);
};

export default ImportPrices;
