import { Button, Typography } from '@mui/material';
import Network, { ServiceEndpoint } from 'common/network';
import ActivityIndicator from 'components/activityIndicator';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { StoreContext } from 'stores';

export interface FinishAuctionViewProps {
	auctionId: string;
	completed: () => void;
}

const FinishAuctionView: React.FunctionComponent<FinishAuctionViewProps> = observer((props) => {
	const { auctionsStore } = React.useContext(StoreContext);
	const biddingStatus = auctionsStore.get_bidding_status(props.auctionId);

	if (!biddingStatus) {
		return <ActivityIndicator>Indlæser</ActivityIndicator>;
	}

	if (biddingStatus.auctionCompleted) {
		props.completed();
		return <div />;
	}

	return (
		<React.Fragment>
			{biddingStatus.hasBiddingEnded && (
				<Typography variant="body1">
					Alle lots er nu lukkede for bud og det er sikkert at lukke auktionen. Ved at lukke auktionen bliver
					den taget af hjemmesiden imens i validere budene. Dette vil blive præsenteret for jer efter i har
					trykket på knappen her:
				</Typography>
			)}
			{!biddingStatus.hasBiddingEnded && (
				<React.Fragment>
					<Typography variant="body1" style={{ fontWeight: 'bold' }}>
						Alle lots er endnu ikke færdige med budgivning
					</Typography>
					<Typography variant="body1">
						Det anbefales ikke at lukke auktionen men du kan stoppe den her. Du vil blive præsenteret for en
						oversigt over den aktuelle bud situation som ved en normal afslutning.
					</Typography>
				</React.Fragment>
			)}
			<Button
				variant="outlined"
				style={{ marginTop: '1em' }}
				onClick={async () => {
					await Network.post(ServiceEndpoint.Node, `/auctions/${props.auctionId}/completeAuction`, {});
					props.completed();
				}}
			>
				Afslut Auktion
			</Button>
		</React.Fragment>
	);
});

export default FinishAuctionView;
