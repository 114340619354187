import * as React from 'react';
import Title from 'components/title';
import { RouteComponentProps } from '@reach/router';
import { StoreContext } from 'stores';
import { useForm } from 'common/form/useForm';
import ActivityIndicator from 'components/activityIndicator';
import { Divider, Button } from '@mui/material';
import PhoneUserDetailsInfo from './components/phoneUserDetailsInfo';
import { observer } from 'mobx-react-lite';

export interface PhoneUserDetailsProps extends RouteComponentProps {
	userId: string;
}

const PhoneUserDetails: React.FunctionComponent<PhoneUserDetailsProps> = observer((props) => {
	const { phoneUsersStore } = React.useContext(StoreContext);
	const { handleSubmit, handleInputChange, inputs } = useForm(async (obj) => {
		await phoneUsersStore.update_user(props.userId, obj);
		return true;
	});

	const user = phoneUsersStore.get_user(props.userId);
	if (!user) {
		return <ActivityIndicator message="Indlæser bruger..." />;
	}

	let title = '';
	if (user.companyName) {
		title = `${user.companyName} ved ${user.firstName} ${user.lastName}`;
	} else {
		title = `${user.firstName} ${user.lastName}`;
	}

	const input = {
		...user,
		...inputs
	};

	return (
		<React.Fragment>
			<Title>{`Telefon byder: ${title}`}</Title>
			<PhoneUserDetailsInfo user={input} handleInputChange={handleInputChange} />
			<Divider style={{ marginTop: '1em', marginBottom: '1em' }} />
			<Button variant="outlined" onClick={handleSubmit}>
				Gem ændringer
			</Button>
		</React.Fragment>
	);
});

export default PhoneUserDetails;
