import * as React from 'react';
import { StoreContext } from 'stores';
import ActivityIndicator from 'components/activityIndicator';
import AuctionModel from 'models/auctionModel';
import config from 'utilities/config';
import moment from 'moment';
import { Paper, ListSubheader, ListItem, ListItemText, List, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		template: {
			paddingLeft: theme.spacing(4)
		}
	})
);

export interface TemplateSelectorProps {
	setSubject: (subject: string) => void;
	setBody: (body: string) => void;
}

const TemplateSelector: React.FunctionComponent<TemplateSelectorProps> = observer((props) => {
	const styles = useStyles();

	const { auctionsStore, mailTemplatesStore } = React.useContext(StoreContext);
	const auctions = auctionsStore.active_auctions();

	const templates = mailTemplatesStore.get_templates();
	if (!auctions || !templates) {
		return <ActivityIndicator />;
	}

	const enrichText = (text: string, auction: AuctionModel): string => {
		let initial = text.replace('[AUCTION_NAME]', auction.title);

		if (auction.catalogId) {
			initial = initial.replace(
				'[AUCTION_LINK]',
				`<a href="${config().public_path}/catalog/${auction.catalogId!}">her</a>`
			);
		}

		if (auction.date) {
			const dateString = moment(auction.date).format(`dddd [d.] do MMMM YYYY [kl] HH:mm`);
			initial = initial.replace('[AUCTION_TIME]', dateString);
		}

		if (auction.biddingDeadline) {
			const dateString = moment(auction.date).format(`dddd [kl] HH:mm`);
			initial = initial.replace(/\[ONLINE_END_TIME\]/g, dateString);
		}

		initial = initial.replace(/\[PAGE_LINK\]/g, `<a href="${config().public_path}">her</a>`);

		return initial;
	};

	if (auctions.length === 0) {
		return null;
	}

	return (
		<Paper>
			{templates.map((template) => (
				<List key={template.id} subheader={<ListSubheader>{template.name}</ListSubheader>}>
					{auctions.map((auction) => (
						<ListItem
							key={auction.id}
							button
							className={styles.template}
							onClick={() => {
								props.setSubject(template.subject);
								props.setBody(enrichText(template.body, auction));
							}}
						>
							<ListItemText primary={auction.title} />
						</ListItem>
					))}
				</List>
			))}
		</Paper>
	);
});

export default TemplateSelector;
