import * as React from 'react';
import { TableRow, TableCell, Button, Typography } from '@mui/material';
import { navigate } from '@reach/router';
import AuctionsItemViewModel from '../viewModels/auctionsItemViewModel';
import config from 'utilities/config';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export interface AuctionCellProps {
	auction: AuctionsItemViewModel;
}

const AuctionCell: React.FunctionComponent<AuctionCellProps> = (props) => {
	const { auction } = props;

	return (
		<TableRow>
			<TableCell style={{ width: '20px' }}>
				{auction.published ? <VisibilityIcon /> : <VisibilityOffIcon />}
			</TableCell>
			<TableCell>
				<Typography variant="body1">{auction.title}</Typography>
			</TableCell>
			<TableCell align="center" style={{ width: '10%' }}>
				<Typography variant="body1">{auction.date}</Typography>
			</TableCell>
			<TableCell style={{ width: '30%' }} align="right">
				<Button
					variant="outlined"
					onClick={() => {
						navigate(`/auctions/${auction.id}`);
					}}
				>
					Rediger
				</Button>
				<Button
					style={{ marginLeft: '1em' }}
					variant="outlined"
					onClick={() => {
						navigate(`/auctions/${auction.id}/viewings`);
					}}
				>
					Eftersyn
				</Button>
				<Button
					style={{ marginLeft: '1em' }}
					variant="outlined"
					onClick={() => {
						navigate(`/catalogs/${auction.id}`);
					}}
				>
					Katalog
				</Button>
				<br />

				<Button
					variant="outlined"
					style={{ marginLeft: '1em' }}
					onClick={() => {
						navigate(`/auctions/${auction.id}/online_bids`);
					}}
				>
					Komm. Bud
				</Button>
				<Button
					variant="outlined"
					style={{ marginLeft: '1em' }}
					onClick={() => {
						navigate(`/catalogs/${auction.id}/latest_online_bids`);
					}}
				>
					Seneste Bud
				</Button>
				{config().features.enablePhoneBidders && (
					<Button
						variant="outlined"
						style={{ marginLeft: '1em' }}
						onClick={() => {
							navigate(`/auctions/${auction.id}/phone_bidders`);
						}}
					>
						Telefon Bydere
					</Button>
				)}

				<Button
					style={{ marginLeft: '1em' }}
					variant="outlined"
					onClick={() => {
						navigate(`/auctions/${auction.id}/complete`);
					}}
				>
					Afslut
				</Button>
			</TableCell>
		</TableRow>
	);
};

export default AuctionCell;
