const isToday = (someDate: Date): boolean => {
	const today = new Date();
	return (
		someDate.getDate() === today.getDate() &&
		someDate.getMonth() === today.getMonth() &&
		someDate.getFullYear() === today.getFullYear()
	);
};

export const formatDate = (date: Date) => {
	return `${new Intl.DateTimeFormat('da-DK', {
		month: 'short',
		day: '2-digit'
	}).format(date)} kl. ${new Intl.DateTimeFormat('da-DK', {
		hour: 'numeric',
		minute: 'numeric'
	}).format(date)}`;
};

export function longDateFormat(date: Date): string {
	return `${new Intl.DateTimeFormat('da-DK', {
		weekday: 'long'
	}).format(date)} den ${new Intl.DateTimeFormat('da-DK', {
		day: 'numeric',
		month: 'long'
	}).format(date)} kl ${new Intl.DateTimeFormat('da-DK', {
		hour: 'numeric',
		minute: 'numeric'
	}).format(date)}`;
}

export function bidDeadlingDate(date: Date): string {
	if (isToday(date)) {
		return `kl. ${new Intl.DateTimeFormat('da-DK', {
			hour: 'numeric',
			minute: 'numeric'
		}).format(date)}`;
	} else {
		return formatDate(date);
	}
}
