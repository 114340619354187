import { Button, Container, TextField, Typography } from '@mui/material';
import { navigate, RouteComponentProps } from '@reach/router';
import { Buffer } from 'buffer';
import ActivityIndicator from 'components/activityIndicator';
import Title from 'components/title';
import iconv from 'iconv-lite';
import { observer } from 'mobx-react-lite';
import Papa from 'papaparse';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { StoreContext } from 'stores/index';
import { ImportedLot, ParseError } from './models/importedLots';

export interface ImportCatalogProps extends RouteComponentProps {
	catalogId: string;
}

enum ImportCatalogState {
	SelectFile,
	ParsedLots,
	Importing
}

const ImportCatalog: React.FunctionComponent<ImportCatalogProps> = observer((props) => {
	const [state, setState] = React.useState(ImportCatalogState.SelectFile);
	const [lots, setLots] = React.useState(new Array<ImportedLot>());
	const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
	const [timeDiff, setTimeDiff] = React.useState(5);
	const { catalogStore } = React.useContext(StoreContext);

	const onDrop = (acceptedFiles: File[]) => {
		const fileReader = new FileReader();
		fileReader.onloadend = (event: any) => {
			const result = event.target.result;
			const buffer = Buffer.from(result, 'binary');
			let text = iconv.decode(buffer, 'CP865');
			// this is a terrible check - but sometimes the encoding seems to change
			if (text.includes('ø') === false && text.includes('ø') === false && text.includes('ø') === false) {
				text = iconv.decode(buffer, 'ISO-8859-1');
			}

			console.log(text);

			convertFile(text);
		};

		fileReader.readAsArrayBuffer(acceptedFiles[0]);
	};

	const convertFile = (file: string) => {
		const csv = Papa.parse(file);
		const data = csv.data as string[][];

		let imported: ImportedLot[] = [];
		try {
			imported = data
				.filter((x) => x[0] !== 'dummy')
				.map((x) => ImportedLot.make(x))
				.filter((x) => x !== undefined)
				.map((x) => x as ImportedLot);
		} catch (ex: any) {
			const err = ex as ParseError;
			setErrorMessage(err.message);
			if (ex.critical) {
				setState(ImportCatalogState.SelectFile);
				return;
			}
		}

		setLots(imported);
		setState(ImportCatalogState.ParsedLots);
	};

	const importLots = async () => {
		setState(ImportCatalogState.Importing);

		let previousLot: ImportedLot | undefined = undefined;
		lots.forEach((x) => {
			if (previousLot) {
				x.setTimeDifference(previousLot.bidDeadline ?? catalog!.auction.biddingDeadline!, timeDiff);
			} else {
				x.setTimeDifference(catalog!.auction.biddingDeadline!, timeDiff);
			}

			previousLot = x;
		});
		await catalogStore.import_lots(props.catalogId, lots);
		navigate(`/catalogs/${props.catalogId}`, { replace: true });
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	const catalog = catalogStore.catalog(props.catalogId);

	if (!catalog) {
		return <ActivityIndicator>Indlæser</ActivityIndicator>;
	}

	return (
		<Container maxWidth="md">
			<Title>Importer lots til katalog {catalog.auction.title} </Title>
			{state !== ImportCatalogState.Importing && catalog.auction.onlineOnlyAuction && (
				<TextField
					label="Tidsforskel mellem hvert lot i minutter"
					style={{ width: '100%', marginBottom: '1em' }}
					type="number"
					value={timeDiff}
					onChange={(x) => setTimeDiff(parseInt(x.currentTarget.value))}
				/>
			)}
			{state === ImportCatalogState.SelectFile && (
				<React.Fragment>
					<div {...getRootProps()}>
						<input {...getInputProps()} accept="text/*" />
						{isDragActive ? (
							<Button variant="outlined">Placer filen her ...</Button>
						) : (
							<Button variant="outlined">Træk filen hertil - Eller tryk for at vælge i en dialog</Button>
						)}
					</div>
				</React.Fragment>
			)}
			{state === ImportCatalogState.ParsedLots && (
				<React.Fragment>
					{/* <ParsedLots lots={lots} /> */}

					<Button color="primary" style={{ marginTop: '1em' }} variant="outlined" onClick={importLots}>
						Importer {lots.length} lots
					</Button>
				</React.Fragment>
			)}
			{state === ImportCatalogState.Importing && (
				<React.Fragment>
					<ActivityIndicator />
					<Typography align="center">Importere</Typography>
				</React.Fragment>
			)}
			{errorMessage && (
				<Typography style={{ marginTop: '1em' }} variant="body1" color="error">
					{errorMessage}
				</Typography>
			)}
		</Container>
	);
});

export default ImportCatalog;
