import * as React from 'react';
import {
	List,
	ListItem,
	ListItemText,
	Divider,
	ListItemSecondaryAction,
	IconButton,
	Paper,
	ListSubheader
} from '@mui/material';
import ActivityIndicator from 'components/activityIndicator';
import EditRounded from '@mui/icons-material/EditRounded';
import { navigate } from '@reach/router';
import { StoreContext } from 'stores/index';
import { observer } from 'mobx-react-lite';
import { CatalogSectionModel } from '../../../models/catalogSectionModel';

export interface CatalogSectionListProps {
	catalogId: string;
}

const CatalogSectionList: React.FunctionComponent<CatalogSectionListProps> = observer((props) => {
	const { catalogStore } = React.useContext(StoreContext);
	const sections = catalogStore.sections(props.catalogId);

	if (!sections) {
		return <ActivityIndicator />;
	}

	const onEdit = (id: string) => {
		navigate(`/catalogs/${props.catalogId}/sections/${id}`);
	};

	return (
		<Paper>
			<List
				subheader={
					<ListSubheader component="div" id="nested-list-subheader">
						Overskrifter
					</ListSubheader>
				}
			>
				{sections.map((x: CatalogSectionModel, index) => (
					<React.Fragment key={x.id}>
						<ListItem
							selected={catalogStore.selectedSection === x.id}
							button
							onClick={() => {
								catalogStore.selectedSection = x.id;
							}}
						>
							<ListItemText primary={x.title} />
							<ListItemSecondaryAction>
								<IconButton onClick={() => onEdit(x.id)} edge="end" aria-label="edit" size="large">
									<EditRounded />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
						{index !== sections.length - 1 && <Divider variant="fullWidth" light />}
					</React.Fragment>
				))}
			</List>
			<Divider variant="middle" />
			<List>
				<ListItem button onClick={() => (catalogStore.selectedSection = undefined)}>
					<ListItemText primary="Se alle" />
				</ListItem>
			</List>
			<Divider variant="middle" />
			<List>
				<ListItem button onClick={() => navigate(`/catalogs/${props.catalogId}/sections/create`)}>
					<ListItemText primary="Tilføj overskrift" />
				</ListItem>
			</List>
		</Paper>
	);
});

export default CatalogSectionList;
