import { Button, Divider } from '@mui/material';
import { RouteComponentProps } from '@reach/router';
import { useForm } from 'common/form/useForm';
import ActivityIndicator from 'components/activityIndicator';
import Title from 'components/title';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { StoreContext } from 'stores';
import UserInfo from './components/userInfo';

export interface OnlineUserDetailsProps extends RouteComponentProps {
	userId: string;
}

const OnlineUserDetails: React.FunctionComponent<OnlineUserDetailsProps> = observer((props) => {
	const { onlineUsersStore } = React.useContext(StoreContext);
	const { handleSubmit, handleInputChange, inputs } = useForm(async (obj) => {
		await onlineUsersStore.update_user(props.userId, obj);
		return false;
	});

	const bidder = onlineUsersStore.get_user(props.userId);
	if (!bidder) {
		return <ActivityIndicator message="Indlæser bruger..." />;
	}

	const onlineUser = bidder.onlineUser;
	if (!onlineUser) {
		return (
			<React.Fragment>
				<Title>{`Online bruger: Bruger er slettet`}</Title>
			</React.Fragment>
		);
	}

	let title = '';
	if (onlineUser.companyName) {
		title = `${onlineUser.companyName} ved ${onlineUser.firstName} ${onlineUser.lastName}`;
	} else {
		title = `${onlineUser.firstName} ${onlineUser.lastName}`;
	}

	const input = {
		...onlineUser,
		...inputs
	};

	return (
		<React.Fragment>
			<Title>{`Online bruger: ${title}`}</Title>
			<UserInfo bidderId={props.userId} user={input} handleInputChange={handleInputChange} />
			<Divider style={{ marginTop: '1em', marginBottom: '1em' }} />
			<Button variant="outlined" onClick={handleSubmit}>
				Gem ændringer
			</Button>
		</React.Fragment>
	);
});

export default OnlineUserDetails;
