import * as React from 'react';
import { Image, SourceType } from './image';
import ImageResource from '../utilities/imageResource';

export interface NewsImageProps {
	image: ImageResource;
	width?: number;
	height?: number;
}

const NewsImage: React.FunctionComponent<NewsImageProps> = ({ image, width, height }) => {
	return <Image width={width} height={height} image={image} sourceType={SourceType.news} />;
};

export default NewsImage;
