import { Button, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { LabelDisplayedRowsArgs } from '@mui/material/TablePagination';
import { navigate } from '@reach/router';
import ActivityIndicator from 'components/activityIndicator';
import TableHeaderLabel from 'components/headerLabel';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { StoreContext } from 'stores';
import AuctionsItemViewModel from '../viewModels/auctionsItemViewModel';
import AuctionCell from './auctionsCell';

export interface AuctionsTableProps {}

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			width: '100%',
			marginTop: theme.spacing(3),
			overflowX: 'auto'
		},
		table: {
			minWidth: 650
		}
	})
);

const AuctionsTable: React.FunctionComponent<AuctionsTableProps> = observer(() => {
	const [page, setPage] = React.useState(1);
	const { auctionsStore } = React.useContext(StoreContext);
	const classes = useStyles();

	const pageCount = auctionsStore.get_pageCount();
	if (!pageCount) {
		return <ActivityIndicator message={'Indlæser auktioner.'} />;
	}

	const totalAuctionCount = auctionsStore.get_totalAuctionCount();
	if (!totalAuctionCount) {
		return <ActivityIndicator message={'Indlæser auktioner..'} />;
	}

	const pageElements = auctionsStore.get_page(page);
	if (!pageElements) {
		return <ActivityIndicator message={'Indlæser auktioner...'} />;
	}

	const viewModels = pageElements.map((x) => {
		return new AuctionsItemViewModel(x);
	});

	const handleChangePage = (event: any, newPage: number) => {
		setPage(newPage);
	};

	const labelDisplayedRows = (paginationInfo: LabelDisplayedRowsArgs) => {
		return '';
	};

	return (
		<Table stickyHeader className={classes.table}>
			<TableHead>
				<TableRow>
					<TableCell></TableCell>
					<TableCell>
						<TableHeaderLabel>Navn</TableHeaderLabel>
					</TableCell>
					<TableCell align="center">
						<TableHeaderLabel>Dato</TableHeaderLabel>
					</TableCell>
					<TableCell align="right">
						{/* <Button
							variant="outlined"
							color="primary"
							style={{ marginRight: "1em" }}
							onClick={() => {
								navigate('/auctions/search');
							}}
						>
							Søg
						</Button> */}
						<Button
							variant="outlined"
							color="primary"
							onClick={() => {
								navigate('/auctions/create');
							}}
						>
							Opret Auktion
						</Button>
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{viewModels.map((auction) => (
					<AuctionCell auction={auction} key={auction.id} />
				))}
			</TableBody>
			<TableFooter>
				<TableRow>
					<TablePagination
						count={totalAuctionCount}
						rowsPerPage={5}
						colSpan={3}
						page={page}
						SelectProps={{
							inputProps: { 'aria-label': 'rows per page' },
							native: true
						}}
						labelDisplayedRows={labelDisplayedRows}
						onPageChange={handleChangePage}
					/>
				</TableRow>
			</TableFooter>
		</Table>
	);
});

export default AuctionsTable;
