import * as React from 'react';
import Title from 'components/title';
import { RouteComponentProps } from '@reach/router';
import { StoreContext } from 'stores/index';
import ActivityIndicator from 'components/activityIndicator';
import { observer } from 'mobx-react-lite';
import {
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	Button,
	TableBody,
	Typography,
	TableFooter,
	Container
} from '@mui/material';
import DateTimePicker from 'components/form/dateTimePicker';
import { useForm } from 'common/form/useForm';
import AuctionViewingModel from 'models/auctionViewingModel';
import AuctionModel from 'models/auctionModel';

export interface EditAuctionViewingsProps extends RouteComponentProps {
	auctionId: string;
}

const EditAuctionViewings: React.FunctionComponent<EditAuctionViewingsProps> = observer((props) => {
	const auctionId = props.auctionId;
	const { auctionsStore } = React.useContext(StoreContext);
	const { handleSubmit, handleInputChange, inputs } = useForm(async (obj) => {
		await auctionsStore.update_auction(auctionId, obj);
		return false;
	});
	const auction = auctionsStore.get_auction(props.auctionId);
	if (!auction) {
		return <ActivityIndicator />;
	}

	const input: AuctionModel = {
		...auction,
		...inputs
	};

	const addViewing = () => {
		const newViewing = new AuctionViewingModel(new Date(), new Date());
		handleInputChange({
			persist: () => {},
			target: {
				name: 'viewings',
				value: [...input.viewings, newViewing]
			}
		});
	};

	const updateViewing = (index: number, from: Date, to: Date) => {
		input.viewings[index] = new AuctionViewingModel(from, to);
		handleInputChange({
			persist: () => {},
			target: {
				name: 'viewings',
				value: input.viewings
			}
		});
	};

	const removeViewing = (index: number) => {
		handleInputChange({
			persist: () => {},
			target: {
				name: 'viewings',
				value: input.viewings.filter((x, i) => i !== index)
			}
		});
	};

	return (
		<Container maxWidth="md">
			<Title>Eftersyn</Title>
			<Paper>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>
								<Typography variant="subtitle2">Start</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="subtitle2">Slut</Typography>
							</TableCell>
							<TableCell align="right">
								<Button variant="outlined" onClick={addViewing}>
									Opret
								</Button>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{input.viewings.map((x, index) => (
							<TableRow key={index}>
								<TableCell component="th" scope="row">
									<DateTimePicker
										disablePast={false}
										label={'Fra'}
										name={'from'}
										value={x.from}
										onChange={(newDate) => {
											updateViewing(index, newDate.target.value, x.to);
										}}
									/>
								</TableCell>
								<TableCell>
									<DateTimePicker
										disablePast={false}
										label={'Til'}
										name={'to'}
										value={x.to}
										onChange={(newDate) => {
											updateViewing(index, x.from, newDate.target.value);
										}}
									/>
								</TableCell>
								<TableCell align="right">
									<Button
										variant="outlined"
										onClick={() => {
											removeViewing(index);
										}}
										color="primary"
									>
										Slet
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TableCell align="left">
								<Button variant="outlined" onClick={handleSubmit} color="primary">
									Gem
								</Button>
							</TableCell>
						</TableRow>
					</TableFooter>
				</Table>
			</Paper>
		</Container>
	);
});

export default EditAuctionViewings;
