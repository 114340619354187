import {
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	TextField
} from '@mui/material';
import { LabelDisplayedRowsArgs } from '@mui/material/TablePagination';
import { navigate, RouteComponentProps } from '@reach/router';
import ActivityIndicator from 'components/activityIndicator';
import TableHeaderLabel from 'components/headerLabel';
import Title from 'components/title';
import { observer } from 'mobx-react-lite';
import PhoneUser from 'models/phoneUser';
import * as React from 'react';
import { StoreContext } from 'stores';

export interface PhoneBiddersProps extends RouteComponentProps {}

const PhoneUsers: React.FunctionComponent<PhoneBiddersProps> = observer(() => {
	const pageCount = 20;
	const [page, setPage] = React.useState<number>(0);
	const [searchQuery, setSearchQuery] = React.useState<string>('');
	const { phoneUsersStore } = React.useContext(StoreContext);

	const users: PhoneUser[] | undefined = phoneUsersStore.get_users();

	if (!users) {
		return <ActivityIndicator message={'Indlæser brugere...'} />;
	}

	const searchedUsers = users.filter((x) => x.contains(searchQuery));

	const pagedUsers = searchedUsers.slice(page * pageCount, page * pageCount + pageCount);

	const onSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const search = event.currentTarget.value;
		setPage(0);
		setSearchQuery(search);
	};

	const handleChangePage = (event: any, newPage: number) => {
		setPage(newPage);
	};

	const labelDisplayedRows = (paginationInfo: LabelDisplayedRowsArgs) => {
		return '';
	};

	return (
		<React.Fragment>
			<Title>Telefon Bydere</Title>
			<Paper>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>
								<TableHeaderLabel>Virksomhed</TableHeaderLabel>
							</TableCell>
							<TableCell>
								<TableHeaderLabel>Fornavn</TableHeaderLabel>
							</TableCell>
							<TableCell>
								<TableHeaderLabel>Efternavn</TableHeaderLabel>
							</TableCell>
							<TableCell>
								<TableHeaderLabel>Adresse</TableHeaderLabel>
							</TableCell>
							<TableCell>
								<TableHeaderLabel>Post</TableHeaderLabel>
							</TableCell>
							<TableCell>
								<TableHeaderLabel>Land</TableHeaderLabel>
							</TableCell>
							<TableCell align="right">
								<TextField
									onChange={onSearch}
									id="standard-search"
									label="Søg"
									type="search"
									margin="dense"
								/>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{pagedUsers.map((x) => (
							<TableRow key={x.id}>
								<TableCell align="left">{x.companyName}</TableCell>
								<TableCell align="left">{x.firstName}</TableCell>
								<TableCell align="left">{x.lastName}</TableCell>
								<TableCell align="left">{x.address}</TableCell>
								<TableCell align="left">{x.postalCode}</TableCell>
								<TableCell align="left">{x.country}</TableCell>
								<TableCell align="right" onClick={() => navigate(`/phone_users/${x.id}`)}>
									<Button variant="outlined">Detaljer</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[5]}
								colSpan={7}
								count={searchedUsers.length}
								rowsPerPage={pageCount}
								page={page}
								SelectProps={{
									inputProps: { 'aria-label': 'rows per page' },
									native: true
								}}
								labelDisplayedRows={labelDisplayedRows}
								onPageChange={handleChangePage}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</Paper>
		</React.Fragment>
	);
});

export default PhoneUsers;
