import { action, makeObservable, observable } from 'mobx';
import { IStore } from 'stores';
import Network, { ServiceEndpoint } from '../common/network';
import { PhoneBidder } from '../models/phoneBidder';
import PhoneUser from '../models/phoneUser';
import { Severity, ToastStore } from './ToastStore';

export class PhoneUsersStore {
	toastStore: ToastStore;
	constructor(private rootStore: IStore) {
		makeObservable(this);
		this.toastStore = rootStore.toastStore;
	}

	@observable users: PhoneUser[] | undefined;
	@observable bidders = new Map<string, PhoneBidder[]>();

	get_users() {
		if (!this.users) {
			this.fetchUsers();
		} else {
			return this.users;
		}
	}

	get_user(userId: string) {
		if (!this.users) {
			this.fetchUsers();
			return undefined;
		}

		const user = this.users.find((x) => x.id === userId);
		return user;
	}

	get_bidders(auctionId: string) {
		const bidders = this.bidders.get(auctionId);
		if (bidders) {
			return bidders;
		} else {
			this.fetchBidders(auctionId);
			return;
		}
	}

	@action
	async update_user(userId: string, changed: any) {
		try {
			await Network.put(ServiceEndpoint.Node, `phone_users/${userId}`, changed);
			this.toastStore.showMessage('Bruger opdateret', Severity.Information);
		} catch (ex: any) {
			this.errorHandler(ex);
		}

		// This doesnt work, and requires more work to work :)
		// if (this.users) {
		// 	const user = this.users.find((x) => x.id === userId);
		// 	if (user) {
		// 		const newUser: PhoneUser = { ...user, ...changed };
		// 		this.users = [...this.users.filter((x) => x.id !== userId), newUser];
		// 		return;
		// 	}
		// }

		this.fetchUsers();
	}

	@action
	async fetchBidders(auctionId: string) {
		try {
			const users = await Network.get<PhoneBidder[]>(ServiceEndpoint.Other, `phone_users/auctions/${auctionId}`);
			this.bidders.set(auctionId, users);
		} catch (ex: any) {
			this.errorHandler(ex);
		}
	}

	@action.bound
	async fetchUsers() {
		try {
			const users = await Network.get<PhoneUser[]>(ServiceEndpoint.Node, 'phone_users');
			this.users = users.map((x: any) => new PhoneUser(x));
		} catch (ex: any) {
			this.errorHandler(ex);
		}
	}

	errorHandler = (ex: any) => {
		console.error(ex);
		if (ex.response) {
			this.toastStore.showMessage(ex.response.data, Severity.Error);
		} else {
			this.toastStore.showMessage(ex.message, Severity.Error);
		}
	};
}
