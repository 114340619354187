import { makeStyles } from '@mui/styles';
import { Router } from '@reach/router';
import AddLotImage from 'pages/addLotImage';
import AddNewsImage from 'pages/addNewsImage';
import Auctions from 'pages/auctions';
import Catalog from 'pages/catalog';
import CompleteAuctionPage from 'pages/completeAuction';
import ConsignmentBids from 'pages/consignmentBidsPage';
import EditAuction from 'pages/editAuction';
import EditAuctionViewings from 'pages/editAuctionViewings';
import EditBidPage from 'pages/editBidPage';
import EditCatalogSection from 'pages/editCatalogSection';
import EditLot from 'pages/editLot';
import EditNews from 'pages/editNews';
import ImportCatalog from 'pages/importCatalog';
import ImportImages from 'pages/importImages';
import LatestBidsPage from 'pages/LatestBidsPage';
import Logon from 'pages/logon';
import Messages from 'pages/messages';
import News from 'pages/news';
import OnlineBidders from 'pages/onlineBidders';
import OnlineUserDetails from 'pages/onlineUserDetails';
import OnlineUsers from 'pages/onlineUsers';
import OrangeBidPage from 'pages/orangeBid';
import PhoneBidders from 'pages/phoneBidders';
import PhoneUserDetails from 'pages/phoneUserDetails';
import PhoneUsers from 'pages/phoneUsers';
import RootView from 'pages/root';
import SearchPage from 'pages/search';
import * as React from 'react';

const useStyles = makeStyles((theme) => ({
	rootElement: {
		margin: theme.spacing(3, 2)
	}
}));

const ContentRouting = (props: any) => {
	let styles = useStyles(props);

	return (
		<div className={styles.rootElement}>
			<Router>
				<Auctions path="/auctions" />
				<EditAuctionViewings path="/auctions/:auctionId/viewings" auctionId="GOT_OVERWRITTEN" />
				<PhoneBidders path="/auctions/:auctionId/phone_bidders" auctionId="GOT_OVERWRITTEN" />
				<OnlineBidders path="/auctions/:auctionId/online_bids" auctionId="GOT_OVERWRITTEN" />
				<ConsignmentBids
					path="/auctions/:auctionId/online_bids/:catalogId/:lotId"
					auctionId="GOT_OVERWRITTEN"
					catalogId="GOT_OVERWRITTEN"
					lotId="GOT_OVERWRITTEN"
				/>
				<EditAuction path="/auctions/:auctionId" auctionId="GOT_OVERWRITTEN" />
				<ImportCatalog path="/catalogs/:catalogId/importCatalog" catalogId="GET_OVERWRITTEN" />
				<ImportImages path="/catalogs/:catalogId/importImages" catalogId="GET_OVERWRITTEN" />

				<Catalog path="/catalogs/:catalogId" catalogId="GOT_OVERWRITTEN" />
				<News path="/news" />
				<OnlineUsers path="/users" />
				<OnlineUserDetails path="/users/:userId" userId="GET_OVERWRITTEN" />
				<Logon path="/logon" />
				<EditLot path="/catalogs/:catalogId/lots/:lotId" catalogId="GOT_OVERWRITTEN" lotId="GOT_OVERWRITTEN" />
				<EditCatalogSection
					path="/catalogs/:catalogId/sections/:sectionId"
					sectionId="GET_OVERWRITTEN"
					catalogId="GET_OVERWRITTEN"
				/>
				<AddLotImage
					path="/catalogs/:catalogId/lots/:lotId/addImage"
					catalogId="GET_OVERWRITTEN"
					lotId="GET_OVERWRITTEN"
				/>
				<RootView path="/" />
				<EditNews path="/news/:newsId" newsId="GET_OVERWRITTEN" />
				<AddNewsImage path="/news/:newsId/addImage" newsId="GET_OVERWRITTEN" />
				<Messages path="/messages" />
				<PhoneUsers path="/phone_users" />
				<PhoneUserDetails path="/phone_users/:userId" userId="GET_OVERWRITTEN" />
				<OrangeBidPage
					path="/orange_bids/:catalogId/:lotId"
					catalogId="GET_OVERWRITTEN"
					lotId="GET_OVERWRITTEN"
				/>
				<LatestBidsPage path="/catalogs/:catalogId/latest_online_bids" catalogId="GET_OVERWRITTEN" />
				<SearchPage path="/auctions/search" />
				<CompleteAuctionPage path="/auctions/:auctionId/complete" auctionId="GET_OVERWRITTEN" />
				<EditBidPage path="/auctions/:auctionId/online_bids/:catalogId/:lotId/:bidId" />
			</Router>
		</div>
	);
};

export default ContentRouting;
