import React from 'react';
import Title from 'components/title';
import { RouteComponentProps } from '@reach/router';
import { Typography } from '@mui/material';

export interface OrangeBidPageProps extends RouteComponentProps {
	catalogId: string;
	lotId: string;
}

const OrangeBidPage: React.FunctionComponent<OrangeBidPageProps> = (props) => {
	return (
		<React.Fragment>
			<Title>Orange Bud</Title>
			<Typography variant="body1" gutterBottom>
				Du er ved at lægge et orange bud på katalog nummer: {props.lotId}
			</Typography>
			<Typography variant="h6">Vælg byder</Typography>
			Online Byder
			<br />
			Telefon Byder
			<br />
			Manuel
		</React.Fragment>
	);
};

export default OrangeBidPage;
