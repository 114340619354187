import Network, { ServiceEndpoint } from 'common/network';
import { ICatalogDto } from 'common/responses/ICatalogDto';
import { ILatestBidDto } from 'common/responses/ILatestBidDto';
import { action, makeObservable, observable, runInAction } from 'mobx';
import OrderedObject from 'models/interfaces/orderedObject';
import { CatalogImageWrap } from 'pages/importImages/models/CatalogImageWrap';
import ReorderList from 'utilities/reorderList';
import { ICreateSalesDto } from '../common/responses/ICreateSalesDto';
import { CatalogModel } from '../models/catalogModel';
import { CatalogSectionModel } from '../models/catalogSectionModel';
import { LotImageModel } from '../models/lotImageModel';
import { LotModel } from '../models/lotModel';
import { LotsSection } from '../models/lotsSection';
import { ImportedLot } from '../pages/importCatalog/models/importedLots';
import { IStore } from './index';
import { Severity, ToastStore } from './ToastStore';

export class CatalogStore {
	toastStore: ToastStore;
	constructor(private rootStore: IStore) {
		makeObservable(this);
		this.toastStore = rootStore.toastStore;
	}

	private is_loading_catalog = new Array<string>();
	@observable private catalogs = new Array<CatalogModel>();
	@observable selectedSection: string | undefined;
	@observable batchUploadProgress: number = 0;

	catalog(catalogId: string): CatalogModel | undefined {
		const catalog = this.catalogs.find((x) => x.id === catalogId);
		if (!catalog) {
			this.fetchCatalog(catalogId);
			return undefined;
		}

		return catalog;
	}

	async create_lot(catalogId: string, lotObject: any) {
		try {
			await Network.post(ServiceEndpoint.Node, `lots/catalogs/${catalogId}`, lotObject);
			this.toastStore.showMessage('Lot oprettet', Severity.Information);
		} catch (ex: any) {
			this.errorHandler(ex);
		}

		this.fetchCatalog(catalogId);
	}

	async update_lot(catalogId: string, lotId: string, lotObject: LotModel) {
		try {
			await Network.put(ServiceEndpoint.Node, `lots/catalogs/${catalogId}/${lotId}`, lotObject, true);
			this.toastStore.showMessage('Lot opdateret', Severity.Information);
		} catch (ex: any) {
			this.errorHandler(ex);
		}

		this.fetchCatalog(catalogId);
	}

	@action async set_visibility(catalogId: string, visible: boolean) {
		const catalog = this.catalogs.find((x) => x.id === catalogId);
		let origState = catalog?.published ?? false;
		if (catalog) {
			catalog.published = visible;
		}

		try {
			await Network.put(
				ServiceEndpoint.Node,
				`catalogs/${catalogId}`,
				{
					published: visible
				},
				true
			);

			const catalog = this.catalog(catalogId);
			catalog!.published = visible;
			this.toastStore.showMessage(`Kataloget er nu ${visible ? 'synligt' : 'skjult'}`, Severity.Information);
		} catch (ex: any) {
			if (catalog) {
				catalog.published = origState;
			}
			this.errorHandler(ex);
		}
	}

	section(catalogId: string, sectionId: string): CatalogSectionModel | undefined {
		const catalog = this.catalog(catalogId);
		if (catalog) {
			return catalog.sections.find((x) => x.id === sectionId);
		}

		return undefined;
	}

	lot(catalogId: string, lotId: string): LotModel | undefined {
		const catalog = this.catalog(catalogId);
		if (catalog) {
			return catalog.lots.find((x) => x.id === lotId);
		}

		return undefined;
	}

	sections(catalogId: string): CatalogSectionModel[] | undefined {
		const catalog = this.catalog(catalogId);
		if (catalog) {
			return catalog.sections;
		}

		return undefined;
	}

	async create_section(catalogId: string, obj: any) {
		delete obj.id;
		try {
			console.debug('Creating section', obj);
			await Network.post(ServiceEndpoint.Node, `catalogs/${catalogId}/sections`, obj);
			this.fetchCatalog(catalogId);
			this.toastStore.showMessage('Sektion lavet', Severity.Information);
		} catch (ex: any) {
			this.errorHandler(ex);
		}
	}

	async delete_section(catalogId: string, sectionId: string) {
		try {
			console.debug('Deleting section');
			await Network.delete(ServiceEndpoint.Node, `catalogs/${catalogId}/sections/${sectionId}`);
			this.fetchCatalog(catalogId);
			this.toastStore.showMessage('Sektion slettet', Severity.Information);
		} catch (ex: any) {
			this.errorHandler(ex);
		}
	}

	async update_section(catalogId: string, sectionId: string, obj: any) {
		try {
			console.debug('Updating section', obj);
			await Network.put(ServiceEndpoint.Node, `catalogs/${catalogId}/sections/${sectionId}`, obj);
			this.fetchCatalog(catalogId);
			this.toastStore.showMessage('Sektion opdateret', Severity.Information);
		} catch (ex: any) {
			this.errorHandler(ex);
		}
	}

	sectioned_lots(catalogId: string, sectionId: string | undefined): LotsSection[] | undefined {
		const catalog = this.catalog(catalogId);
		if (!catalog) {
			return undefined;
		}

		if (sectionId) {
			const section = catalog.sections.find((x) => x.id === sectionId);
			if (!section) {
				return undefined;
			}

			const lots = catalog.lotsForSection(sectionId);
			return [new LotsSection(sectionId, section.title, lots)];
		} else {
			if (catalog.sections.length === 0) {
				return [new LotsSection('-999', 'Lots uden sektion', catalog.lots)];
			}

			const sections = catalog.sections;
			if (sections.length > 0) {
				const firstSection = sections[0];
				let orphanLots: LotModel[] = [];
				if (firstSection) {
					orphanLots = catalog.lots.filter((x) => x.catalogNumber < firstSection.fromLot);
				}

				const sectioned = catalog.sections.map(
					(x) => new LotsSection(x.id, x.title, catalog.lotsForSection(x.id))
				);

				if (orphanLots.length > 0) {
					return [new LotsSection('orphans', 'Lots uden sektion', orphanLots), ...sectioned];
				} else {
					return sectioned;
				}
			} else {
				return [new LotsSection('no lot section', 'Lots uden sektion', catalog.lots)];
			}
		}
	}

	async import_lots(catalogId: string, lots: ImportedLot[]) {
		try {
			await Network.post(ServiceEndpoint.Node, `lots/catalogs/${catalogId}/batch`, lots);
			this.fetchCatalog(catalogId);
		} catch (ex: any) {
			this.errorHandler(ex);
		}
	}

	async add_images(
		catalogId: string,
		lotId: string,
		files: File[],
		callback: (completed: number, total: number) => void
	) {
		const promises = files.map((file) => this.upload_image(catalogId, lotId, file));
		try {
			var counter = 0;
			for (const promise of promises) {
				await promise;
				counter += 1;
				callback(counter, promises.length);
			}
		} catch (ex: any) {
			this.errorHandler(ex);
		}

		this.fetchCatalog(catalogId);
	}

	@action
	async batch_upload_prices(createSale: ICreateSalesDto) {
		try {
			await Network.post(ServiceEndpoint.Node, `auctions/${createSale.auctionId}/createSales`, createSale);
		} catch (ex: any) {
			this.errorHandler(ex);
		}

		this.catalogs = this.catalogs.filter((x) => x.auction.id !== createSale.auctionId);
	}

	async upload_image(catalogId: string, lotId: string, file: File): Promise<void> {
		type UploadResponse = {
			uploadUrl: string;
			entityName: string;
		};

		// safari likes to cache even though we ask it not to.
		const uploadUrlRequest = await Network.get<UploadResponse>(
			ServiceEndpoint.Node,
			`import/upload_url?safaricanbiteme=${Math.random()}`
		);

		await Network.put(ServiceEndpoint.Other, uploadUrlRequest.uploadUrl, file, false, {
			headers: {
				'Content-Type': `image/jpeg`
			}
		});

		await Network.post(ServiceEndpoint.Node, `images/catalogs/${catalogId}/lots/${lotId}/import`, {
			entityName: uploadUrlRequest.entityName
		});
	}

	@action
	async batch_add_images(
		catalogId: string,
		files: CatalogImageWrap[],
		callback: (completed: number, total: number) => void
	) {
		for (let i = 0; i < files.length; i++) {
			const element = files[i];

			try {
				await this.upload_image(catalogId, element.lotId, element.file);
				callback(i + 1, files.length);
			} catch (ex: any) {
				this.errorHandler(ex);
			}
		}

		this.fetchCatalog(catalogId);
	}

	@action
	async delete_image(catalogId: string, lotId: string, imageId: string) {
		const catalog = this.catalog(catalogId);
		if (!catalog) {
			console.error('No catalog loaded yet');
			return;
		}

		const lot = catalog.lots.find((x) => x.id === lotId);
		if (!lot) {
			console.error('invalid lot id');
			return;
		}

		// TODO: Figure out why lot.images isnt observable
		lot.images = lot.images.slice().filter((x) => x.id !== imageId);

		try {
			await Network.delete(ServiceEndpoint.Node, `/images/catalogs/${catalogId}/lots/${lotId}/${imageId}`);
			await this.fetchCatalog(catalogId);
			this.toastStore.showMessage('Billede slettet', Severity.Information);
		} catch (ex: any) {
			this.errorHandler(ex);
		}
	}

	@action
	async reorder_images(catalogId: string, lotId: string, imageId: string, moveUp: boolean) {
		const catalog = this.catalog(catalogId);
		if (!catalog) {
			this.fetchCatalog(`${catalogId}`);
			return undefined;
		}

		const lot = catalog.lots.find((x) => x.id === lotId);
		if (!lot) {
			this.toastStore.showMessage(`Invalid lot selected: ${lotId} for catalog ${catalogId}`, Severity.Error);
			return undefined;
		}

		const images = lot.images;
		if (!images) {
			console.error('Trying to sort null list');
			return;
		}

		ReorderList(
			this.toastStore,
			images.slice(),
			imageId,
			moveUp,
			async (ordering: string[]) => {
				await Network.put(
					ServiceEndpoint.Node,
					`images/catalogs/${catalogId}/lots/${lotId}/updateOrder`,
					ordering
				);
			},
			(items: OrderedObject[]) => {
				runInAction(() => {
					lot.images = items as LotImageModel[];
				});
			}
		);
	}

	@action
	private async fetchCatalog(catalogId: string) {
		if (this.is_loading_catalog.find((x) => x === catalogId)) {
			return;
		}

		const beforeLoad = performance.now();

		console.debug(`Load Catalog: ${catalogId}`);

		this.is_loading_catalog.push(catalogId);
		const catalog = await Network.get<ICatalogDto>(ServiceEndpoint.Node, `catalogs/${catalogId}`);

		const newCatalog = new CatalogModel(catalog);

		const afterLoad = performance.now();

		console.debug(`Catalog Loaded ${catalogId} - Load took ${afterLoad - beforeLoad} miliseconds`);

		runInAction(() => {
			this.catalogs = [...this.catalogs.filter((x) => x.id !== catalogId), newCatalog];
		});

		this.is_loading_catalog = this.is_loading_catalog.filter((x) => x !== catalogId);
	}

	latestBids(catalogId: string, count: number): ILatestBidDto[] | undefined {
		const bids = this._latestBids.get(catalogId);
		if (!bids) {
			this.fetchLatestBids(catalogId, count);
			return undefined;
		}

		return bids;
	}

	@observable private _latestBids = new Map<string, ILatestBidDto[]>();

	private async fetchLatestBids(catalogId: string, count: number) {
		const data = await Network.get<ILatestBidDto[]>(
			ServiceEndpoint.Node,
			`catalogs/${catalogId}/latest_bids?count=${count}`
		);
		this._latestBids.set(catalogId, data);
	}

	errorHandler = (ex: any) => {
		console.error(ex);
		if (ex.response) {
			this.toastStore.showMessage(ex.response.data, Severity.Error);
		} else if (ex.message) {
			this.toastStore.showMessage(ex.message, Severity.Error);
		} else if (ex) {
			this.toastStore.showMessage(ex, Severity.Error);
		}
	};
}
