import * as React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { StoreContext } from 'stores';
import { observer } from 'mobx-react-lite';
import { navigate } from '@reach/router';

export interface CatalogMenuProps {
	catalogId: string;
}

const CatalogImportMenu: React.FunctionComponent<CatalogMenuProps> = observer(({ catalogId }) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const { catalogStore } = React.useContext(StoreContext);
	const catalog = catalogStore.catalog(catalogId);
	if (!catalog) {
		return null;
	}

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<Button
				variant="outlined"
				style={{ marginTop: '1em', width: '100%' }}
				aria-controls="simple-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				Importer
			</Button>
			<Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
				<MenuItem onClick={() => navigate(`/catalogs/${catalogId}/importCatalog`)}>Katalog</MenuItem>
				<MenuItem onClick={() => navigate(`/catalogs/${catalogId}/importImages`)}>Billeder</MenuItem>
			</Menu>
		</React.Fragment>
	);
});

export default CatalogImportMenu;
