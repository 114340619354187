import * as React from 'react';
import { Typography } from '@mui/material';

export interface TableHeaderLabelProps {
	children: React.ReactNode;
}

const TableHeaderLabel: React.FunctionComponent<TableHeaderLabelProps> = ({ children }) => {
	return (
		<Typography variant="body1" style={{ fontWeight: 'bold' }}>
			{children}
		</Typography>
	);
};

export default TableHeaderLabel;
