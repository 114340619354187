import {
	Button,
	Container,
	FormControl,
	FormControlLabel,
	FormGroup,
	Input,
	InputLabel,
	Switch,
	TextField,
	Theme
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { navigate, RouteComponentProps } from '@reach/router';
import { useForm } from 'common/form/useForm';
import ActivityIndicator from 'components/activityIndicator';
import DateTimePicker from 'components/form/dateTimePicker';
import Title from 'components/title';
import { observer } from 'mobx-react-lite';
import AuctionModel from 'models/auctionModel';
import SureDialog from 'pages/messages/components/sureDialog';
import * as React from 'react';
import { StoreContext } from 'stores';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		element: {
			padding: theme.spacing(2, 0, 0, 0)
		},
		divider: {
			margin: theme.spacing(2, 2, 2, 2)
		}
	})
);

export interface EditAuctionProps extends RouteComponentProps {
	auctionId: string;
}

const EditAuction: React.FunctionComponent<EditAuctionProps> = observer((props) => {
	const auctionId = props.auctionId;
	const styles = useStyles();

	const [showDialog, setShowDialog] = React.useState(false);
	const { auctionsStore } = React.useContext(StoreContext);

	const isCreatingNew = auctionId === 'create';

	let auction: AuctionModel | undefined = undefined;
	if (!isCreatingNew) {
		auction = auctionsStore.get_auction(auctionId);
	}

	const defaultAuction = {
		id: '',
		completed: false,
		title: 'Ny Auktion',
		date: new Date(),
		biddingDeadline: new Date(),
		canBid: true,
		copyDanDelay: 2880,
		viewings: [],
		onlineOnlyAuction: false,
		note: ''
	};

	const initial: any = isCreatingNew ? defaultAuction : {};

	const { handleSubmit, handleInputChange, inputs } = useForm(async (obj) => {
		obj.biddingDeadline = obj.biddingDeadline ? obj.biddingDeadline.toJSON() : undefined;
		obj.date = obj.date ? obj.date.toJSON() : undefined;

		if (isCreatingNew) {
			delete obj.viewings;
			await auctionsStore.create_auction(obj).then(() => {
				navigate('/auctions');
			});
		} else {
			await auctionsStore.update_auction(auctionId, obj);
		}

		return false;
	}, initial);

	if (!auction && auctionId !== 'create') {
		return <ActivityIndicator />;
	}

	const initialInput: any = isCreatingNew ? defaultAuction : auction;

	const input = {
		...initialInput,
		...inputs
	};

	const handleDelete = () => {
		auctionsStore.delete_auction(props.auctionId);
		navigate('/auctions', { replace: true });
	};

	return (
		<Container maxWidth="sm">
			<Title>{isCreatingNew ? 'Opret auktion' : 'Rediger Auktion'}</Title>
			<FormGroup row={false}>
				<FormControlLabel
					style={{ marginBottom: '1em' }}
					control={
						<Switch
							checked={input.onlineOnlyAuction ?? false}
							onChange={(e) => {
								handleInputChange(e);
							}}
							name="onlineOnlyAuction"
							value={input.onlineOnlyAuction ?? false}
						/>
					}
					label={input.onlineOnlyAuction ? 'Netauktion' : 'Traditionel Auktion'}
				/>

				<FormControl variant="standard">
					<InputLabel htmlFor="component-simple">Titel</InputLabel>
					<Input id="component-simple" name="title" value={input.title} onChange={handleInputChange} />
				</FormControl>
				<FormControl className={styles.element}>
					<DateTimePicker
						label={
							input.onlineOnlyAuction
								? 'Slut tidspunkt for online budgivning - Kan overstyres af de enkelte lots'
								: 'Slut tidspunkt for online budgivning'
						}
						value={input.biddingDeadline}
						name="biddingDeadline"
						onChange={handleInputChange}
						disablePast={false}
					/>
				</FormControl>
				{!input.onlineOnlyAuction && (
					<FormControl className={styles.element}>
						<DateTimePicker
							disablePast={false}
							label={'Auktion start i sal'}
							value={input.date}
							name="date"
							onChange={handleInputChange}
						/>
					</FormControl>
				)}
				<FormControl>
					<TextField
						name="copyDanDelay"
						label="Hvornår skal CopyDan billeder skjules"
						type="number"
						value={input.copyDanDelay}
						onChange={handleInputChange}
						margin="normal"
					/>
				</FormControl>

				<FormControl>
					<TextField
						name="note"
						label="Note"
						type="text"
						value={input.note}
						onChange={handleInputChange}
						margin="normal"
					/>
				</FormControl>
			</FormGroup>
			<FormGroup style={{ paddingTop: '2em' }}>
				<FormControlLabel
					control={
						<Switch
							checked={input.published ?? false}
							onChange={(e) => {
								handleInputChange(e);
							}}
							name="published"
							value={input.published ?? false}
						/>
					}
					label={
						input.published ? 'Auktionen kan ses på hjemmesiden' : 'Auktionen kan ikke ses på hjemmesiden'
					}
				/>

				<FormControlLabel
					control={
						<Switch
							checked={input.canBid}
							onChange={(e) => {
								handleInputChange(e);
							}}
							name="canBid"
							value={input.canBid}
						/>
					}
					label={input.canBid ? 'Der kan bydes online' : 'Der kan ikke bydes online'}
				/>
			</FormGroup>

			<div style={{ paddingTop: '2em' }}>
				<Button variant="outlined" color="primary" onClick={handleSubmit}>
					Gem Ændringer
				</Button>

				<Button
					style={{ float: 'right' }}
					variant="outlined"
					color="secondary"
					onClick={() => setShowDialog(true)}
				>
					Slet Auktion
				</Button>
			</div>

			<SureDialog
				open={showDialog}
				body="Vil du slette auktionen?"
				acceptMessage="Slet"
				handleClose={(perform) => {
					setShowDialog(false);
					if (!perform) {
						return;
					}

					handleDelete();
				}}
			/>
		</Container>
	);
});

export default EditAuction;
