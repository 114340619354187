import { DateTimePicker as MUIDateTimePicker } from '@mui/lab';
import { TextField } from '@mui/material';
import * as React from 'react';

interface DateTimePickerProps {
	label: string;
	name: string;
	value?: Date;
	onChange: (e: any) => void;
	disablePast?: boolean;
}

const DateTimePicker: React.FunctionComponent<DateTimePickerProps> = (props) => {
	const disablePast = props.disablePast === undefined ? true : props.disablePast!;

	const labelText = props.label;
	const value = props.value ? props.value : new Date();

	return (
		<MUIDateTimePicker
			renderInput={(props) => <TextField {...props} />}
			label={labelText}
			ampm={false}
			disablePast={disablePast}
			value={value}
			inputFormat={'dd/MM/yyyy HH:mm'}
			onChange={(e) => {
				props.onChange({
					target: {
						name: props.name,
						value: e
					},
					persist: () => {}
				});
			}}
		/>
	);
};

export default DateTimePicker;
