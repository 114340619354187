import * as React from 'react';
import Title from 'components/title';
import { RouteComponentProps, navigate } from '@reach/router';
import CatalogSectionList from './components/catalogSectionList';
import { Grid, Button, Card, CardContent, Typography, TextField } from '@mui/material';
import CatalogLots from './components/catalogLots';
import CatalogImportMenu from './components/catalogImportMenu';
import { StoreContext } from 'stores/index';
import ActivityIndicator from 'components/activityIndicator';
import { observer } from 'mobx-react-lite';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import WarningIcon from '@mui/icons-material/Warning';
import makeStyles from '@mui/styles/makeStyles';
import { formatDate } from 'utilities/dateFormatter';

const useStyles = makeStyles((theme) => ({
	leftIcon: {
		marginRight: theme.spacing(1)
	},
	warningBox: {
		marginTop: theme.spacing(1)
	}
}));

export interface CatalogProps extends RouteComponentProps {
	catalogId: string;
}

const Catalog: React.FunctionComponent<CatalogProps> = observer((props) => {
	const styles = useStyles();
	const { catalogStore } = React.useContext(StoreContext);
	const catalog = catalogStore.catalog(props.catalogId);
	if (!catalog) {
		return <ActivityIndicator message={'Indlæser katalog...'} />;
	}

	const toggleVisibility = (visible: boolean) => {
		catalogStore.set_visibility(props.catalogId, visible);
	};

	const dateString = () => {
		if (!catalog.auction.date) {
			return '';
		}

		return `den ${formatDate(catalog.auction.date)}`;
	};

	return (
		<React.Fragment>
			<Title>
				Katalog for {catalog.auction.title} {dateString()}
			</Title>
			<Grid container spacing={2}>
				<Grid item xs={2}>
					<TextField
						style={{ marginBottom: '1em', width: '100%' }}
						label="Hop til katalog #"
						type="outlined"
						onKeyDown={(e) => {
							if (e.key !== 'Enter') {
								return;
							}

							const value: number | undefined = Number((e.target as any).value);
							if (isNaN(value)) {
								return;
							}

							const lot = catalog.lots.find((x) => x.catalogNumber === value);
							if (lot) {
								navigate(`/catalogs/${props.catalogId}/lots/${lot.id}`);
							}
						}}
					/>
					<CatalogSectionList catalogId={props.catalogId} />
					<Button
						style={{ marginTop: '1em', width: '100%' }}
						variant="outlined"
						onClick={() => navigate(`/catalogs/${props.catalogId}/lots/create`)}
					>
						Tilføj Lot
					</Button>
					<CatalogImportMenu catalogId={props.catalogId} />
					{!catalog.published && (
						<Button
							style={{ marginTop: '1em', width: '100%' }}
							variant="outlined"
							onClick={() => toggleVisibility(true)}
						>
							<VisibilityIcon className={styles.leftIcon} />
							Publicer katalog
						</Button>
					)}
					{catalog.published && (
						<Button
							style={{ marginTop: '1em', width: '100%' }}
							variant="outlined"
							onClick={() => toggleVisibility(false)}
						>
							<VisibilityOffIcon className={styles.leftIcon} />
							Upublicer katalog
						</Button>
					)}
					{catalog.published && !catalog.auction.published && (
						<Card className={styles.warningBox}>
							<CardContent>
								<WarningIcon />
								<Typography variant="body1">
									Vær opmærksom på at auktionen ikke er publiceret og derfor er kataloget ikke synligt
									på hjemmesiden
								</Typography>
							</CardContent>
						</Card>
					)}
				</Grid>
				<Grid item xs={10}>
					<CatalogLots catalogId={props.catalogId} />
				</Grid>
			</Grid>
		</React.Fragment>
	);
});

export default Catalog;
