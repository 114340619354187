import { ISaleDto } from './ISaleDto';
import ImportedPrice from '../../pages/importPrices/models/importedPrice';
export class SaleDto implements ISaleDto {
	catalogNumbers: string[];
	amount: number;
	currency: string;
	consignmentBidId: string | undefined;
	constructor(objects: ImportedPrice[]) {
		this.catalogNumbers = objects.map((x) => String(x.catalogNumber));
		this.amount = objects.map((x) => x.price).reduce((x, y) => Math.max(x, y));
		this.currency = 'DKK';
		this.consignmentBidId = undefined; // because I just dont know
	}
}
