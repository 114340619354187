import { CatalogModel } from '../../../models/catalogModel';

export class CatalogImageWrap {
	file: File;
	name: string;
	catalogNumber: number;
	lotId: string;
	order: number;

	constructor(file: File, catalog: CatalogModel) {
		this.file = file;
		this.name = file.name;

		const result = /(\d+)([A-Za-z]?)/.exec(this.name);
		if (result) {
			const catalogNumber = result[1];
			const order = result[2];
			this.catalogNumber = parseInt(catalogNumber);
			this.order = order.length > 0 ? order.charCodeAt(0) - 97 : 0;
		} else {
			this.catalogNumber = 1;
			this.order = 0;
		}

		let matchingLot = catalog.lots.find((x) => x.catalogNumber === this.catalogNumber);
		if (matchingLot) {
			this.lotId = matchingLot.id;
		} else {
			throw new Error('Failed to find a lot with catalog number: ' + this.catalogNumber);
		}
	}
}
