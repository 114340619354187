import { CognitoUser } from '@aws-amplify/auth';
import Amplify, { Auth } from 'aws-amplify';
import axios from 'axios';
import Network, { ServiceEndpoint } from 'common/network';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import config from 'utilities/config';
import { IBidderDto } from './../common/responses/IBidderDto';
import { IStore } from './index';

export class UserStore {
	constructor(private rootStore: IStore) {
		makeObservable(this);
		Amplify.configure({
			Auth: {
				region: config().cognito.region,
				userPoolId: config().cognito.poolId,
				userPoolWebClientId: config().cognito.clientId
			}
		});

		Auth.currentAuthenticatedUser(undefined)
			.then(async (user) => {
				const groups = (await Auth.currentSession()).getAccessToken().payload['cognito:groups'] as String[];
				if (groups.includes('admins') === false) {
					console.error('Not admin user');
					this.logout();
					return new Error('Du er ikke en admin');
				}

				runInAction(() => {
					this.user = user;
				});
			})
			.catch((x) => {});

		this.setAuthenticationHeader();

		Network.get<IBidderDto>(ServiceEndpoint.User, 'bidder')
			.then((x) => {
				runInAction(() => {
					this.profile = x;
				});
			})
			.catch((ex) => {
				console.debug('Failed to get profile');
			});
	}

	@observable user?: CognitoUser = undefined;
	@observable profile?: IBidderDto = undefined;

	@computed get isAuthenticated() {
		return !!this.user;
	}

	@action public async logout() {
		await Auth.signOut();
		this.user = undefined;
		delete axios.defaults.headers.common['Authorization'];
	}

	async setAuthenticationHeader() {
		try {
			const session = await Auth.currentSession();
			const accessToken = session.getAccessToken().getJwtToken();
			if (accessToken) {
				axios.defaults.headers.common['Authorization'] = accessToken;
			} else {
				return this.logout();
			}
		} catch (x) {
			delete axios.defaults.headers.common['Authorization'];
		}
	}

	public async login(username: string, password: string): Promise<Error | undefined> {
		try {
			const user: CognitoUser = await Auth.signIn(username, password);
			const currentSession = await Auth.currentSession();
			const accessToken = currentSession.getAccessToken();

			const groups = accessToken.payload['cognito:groups'];
			if (!groups) {
				throw new Error('Du er ikke en admin');
			}

			if (groups.includes('admins') === false) {
				console.error('Not admin user');
				this.logout();
				throw new Error('Du er ikke en admin');
			}

			runInAction(() => {
				this.user = user;
			});

			try {
				const profileResponse = await Network.get<IBidderDto>(ServiceEndpoint.User, 'bidder');
				runInAction(() => {
					this.profile = profileResponse;
				});

				return undefined;
			} catch (ex: any) {
				throw new Error('Kunne ikke logge dig ind.');
			}
		} catch (ex: any) {
			if (ex as String) {
				throw new Error(ex);
			}
			throw new Error('Kunne ikke logge dig ind. Forkert bruger eller kodeord.');
		}
	}
}
